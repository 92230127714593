import React from 'react'
import { useState,useEffect,useLayoutEffect } from 'react'
import StoryCarousel from './successStories/storyCarousel'
import StoryForm from './storyForm'
import useAxios from '../api/useAxios'
export default function StoryPanel() {
    const api=useAxios()
    const [storyData,setData]=useState([])
    const [loading,setLoading]=useState(true)
    const getStories=()=>{
        api.get('/stories').
        then((response=>{
            console.log('got')
            setData(response.data)
            setLoading(false)
        })).catch(error=>
            console.log(error)
        )
    }
    useLayoutEffect(() => {
        getStories()
    },[]);
  return (
    <div>
        <div>
            <StoryForm/>
        </div>
    </div>
  )
}
