import React from "react";
import { useState } from "react";
export default function ModalForm2(props) {
    const [qualification, setQualification] = useState('');
    const [passedYear, setPassedYear] = useState('');
    const [course, setCourse] = useState('');

    const onQualificationChange = (event) => {
        setQualification(event.target.value);
        
       }
    const onPassedYearChange = (event) => {
        setPassedYear(event.target.value);
        
       }
    const onCourseChange = (event) => {
        setCourse(event.target.value);
        
       }
 
    return (
        <React.Fragment>
        <form className="md:px-6" onSubmit={(e)=>{
            e.preventDefault();
            let data={
                qualification:qualification,
                passedYear:passedYear,
                course:course,
            }
            props.submitHandle(data);
        }}>
            <h1 className="text-center md:text-2xl text-primary3 font-tittilium md:my-3 my-3">Now enter the academic details</h1>
            <div className="flex justify-between items-center">
                <label className="text-primary3 font-dosis md:text-lg">Your highest qualification</label>
                <select onChange={onQualificationChange} name='qualification' className="rounded font-dosis border border-solid border-primary3 py-1 px-3 md:text-lg">
                    <option value="">-Please choose an option-</option>
                    <option value="master">Master</option>
                    <option value="bachelor">Bachelor</option> 
                    <option value="pcl">PCL</option>
                    <option value="+2">+2</option>
                    <option value="other">other</option>          
                </select>
            </div>
            <div className="flex justify-between mt-3 items-center">
                <label className="text-primary3 font-dosis md:text-lg">Passed Year</label>
                <input onChange={onPassedYearChange} type="text" name='passedYear' pattern="[0-9]{4}" className="rounded font-dosis border border-solid border-primary3 py-1 px-3 md:text-lg"/>
            </div>
            <div className="flex justify-between mt-3 items-center">
                <label className="text-primary3 font-dosis md:text-lg">course you want to study</label>
                <select onChange={onCourseChange} name='course' className="rounded font-dosis border border-solid border-primary3 py-1 px-3 md:text-lg">
                    <option value="">-Please choose an option-</option>
                    <option value="Architecture,Building and Planning">Architecture,Building and Planning</option><option value="Commerce">Commerce</option>
                    <option value="Art and Design">Art and Design</option><option value="Computer Science and IT">Computer Science and IT</option>
                    <option value="Culinary Arts">Culinary Arts</option>
                    <option value="Education and Trainning">Education and Trainning</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Hair,Beauty and Personal Care">Hair,Beauty and Personal Care</option>
                    <option value="Public Health">Public Health</option>
                    <option value="Healthcare and Medicine">Healthcare and Medicine</option>
                    <option value="Social Work">Social Work</option>
                    <option value="Information Science Mathematics">Information Science Mathematics</option>
                    <option value="Land and Environment">Land and Environment</option>
                    <option value="Language Programmes">Language Programmes</option>
                    <option value="Law">Law</option>
                    <option value="Nursing">Nursing</option>
                    <option value="Media/Journalism/Communication">Media/Journalism/Communication</option>
                    <option value="Military Studies">Military Studies</option>
                    <option value="Science and Mathematics">Science and Mathematics</option>
                    <option value="Social Studies and Communication">Social Studies and Communication</option>
                    <option value="Sports">Sports</option>
                    <option value="Travel Tourism and Hospitality">Travel Tourism and Hospitality</option>
                    <option value="Accounting,Marketing,Business">Accounting,Marketing,Business</option>
                    <option value="Agriculture">Agriculture</option>
                </select>
            </div>
           
            <button type="submit" className='font-dosis bg-primary2 md:py-2 md:px-4 rounded-full text-white md:my-6  hover:text-primary2 hover:bg-white hover:border hover:border-primary2 py-2 px-3 text-xl my-6'>Next</button>
        </form>
        </React.Fragment>
    );
};