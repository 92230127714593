import React from 'react'
import pic1 from "../../assets/images/p1.jpg";

export default function PhotoSection() {
  return (
    <div className=''>
        <div className='flex bg-primary2 from-primary2 to-primary3 py-6 md:px-12 px-2 gap-x-2 justify-around md:gap-x-12'>
            <img src={pic1} alt="" className='md:h-96 h-72 rounded-2xl opacity-60 scale-x-120'/>
            <p className='font-raleway text-white md:text-xl md:py-10 text-sm'>
              We understand your desire to explore educational institutions beyond borders and across the oceans. Be it a break from monotony, seeking better oppurtunities or a step towards your grand ambition in life.
              We make it our mission to help you find you the best possible destination and institution to help you secure your future.<br/><br/>
              We have a team of experts with best resources at their disposal to safeguard every step of your adventure.
              Our vast network in different nations will help us place you exactly where you would ever want to be. We have always made it our 
              priority to put our potential to best use so that you and your family can take it easy and only focus on whats really important for you.
              
            </p>
        </div>
        <div className='flex py-6 px-6 mt-2'>
        
        <div className='bg-officeBg bg-fixed w-full md:w-screen md:h-64 md:bg-cover bg-no-repeat bg-contain h-64'>
          .
        </div>
        </div>
    </div>
  )
}
