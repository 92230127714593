import React from 'react'
import Intro from '../components/contactHead/intro'
import Footer from '../components/footer/footer'
import Navbar from '../components/navbar/navbar'
import OfficeMap from '../components/officemap/officeMap'

export default function 
ContactUs() {
  return (
    <div className='overflow-x-hidden'>
        <Navbar/>
        <Intro/>
        <OfficeMap/>
        <Footer/>
    </div>
  )
}
