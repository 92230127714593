import React from 'react'
import Service from './service'
import serviceimg1 from '../../assets/images/service1.png'
import serviceimg2 from '../../assets/images/service2.png'
import serviceimg3 from '../../assets/images/service3.png'
import { useState } from 'react'
import ServiceSlide from './serviceSlide'
export default function Services() {
    
    const data=[
        {
            title:[
                {
                    word:'Educational',
                    highlight:false
                },
                {
                    word:'Counseling',
                    highlight:true
                }
            ],
            leftDirection:true,
            img:serviceimg1,
            link:'/contact',
            linkName:'TALK TO EXPERT',
            description:"Our team of experts will work with you to identify your strengths and interests and help you choose the best academic path for your future."
        },
        {
            title:[
                {
                    word:'Test',
                    highlight:false
                },
                {
                    word:'Preparation',
                    highlight:true
                }
            ],
            leftDirection:false,
            img:serviceimg2,
            link:'/contact',
            linkName:'LEARN MORE',
            description:"Our team will help you prepare for standardized tests such as the IELTS, PTE, SAT, GRE, GMAT, and TOEFL."
        },
        {
            title:[
                {
                    word:'High',
                    highlight:false
                },
                {
                    word:'Visa',
                    highlight:true
                },
                {
                    word:'Success',
                    highlight:true
                },
                {
                    word:'Rate',
                    highlight:false
                }
            ],
            leftDirection:true,
            img:serviceimg3,
            link:'/contact',
            linkName:'LEARN MORE',
            description:"Our team will assist you with the visa and immigration process, making sure that everything is taken care of smoothly and efficiently."
        },
        {
            title:[
                {
                    word:'Best',
                    highlight:false
                },
                {
                    word:'Infrastructure',
                    highlight:true
                }
            ],
            leftDirection:false,
            img:serviceimg3,
            link:'/contact',
            linkName:'LEARN MORE',
            description:"we offer personalized infrastructure services tailored to meet the unique needs of each student. Our team of experts works tirelessly to identify the best infrastructure solutions, from finding institutions with modern facilities to recommending the latest technologies."
        },
    ]
    const serviceComponents=data.map((service)=><Service data={service}/>)
  return (
    <div className='my-8'>
        <p className='px-6 text-center text-headlg tracking-wide font-raleway text-tblack relative z-90 md:text-2xl'>
            <span className=''>Unlock your</span> <span className=' text-primary3'>potential</span> <span className='font-raleway text-headlg'>with our </span>
            <span className=''>consultancy </span><span className=' text-primary3'>services</span>
        </p>
        <div className='md:hidden'><ServiceSlide data={data}/></div>
        
        <div className='hidden md:flex md:flex-col'>
            {serviceComponents}
        </div>
    </div>
  )
}
