import React from 'react'
import { useState } from 'react'
import useAxios from '../../api/useAxios'
import { Toaster,toast } from 'react-hot-toast'

export default function DocumentsPanel() {
    const [category,setCategory]=useState('')
    const [done,setDone]=useState(false)
    const [error,setError]=useState(null)
    let api=useAxios()
    const addCategory=()=>{
        const data={
            name:category
        }
        console.log('cat',data)
        api.post('api/student/document-category/',data).
        then(response=>
            setDone(true)
            
        ).catch(
            (error)=>{
            setError(error.response.data.detail)
            console.log(error.response.data)}
        )
    }
  return (
    <div>
        <Toaster/>
        {done?
        <div className='flex justify-center items-center'>
        <div className='flex flex-col items-center gap-y-4  px-4 py-2' >
        <h1 className='font-raleway'>category Added</h1>
        <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
           setCategory('')
            setDone(false)
        }}>Ok</button>
        </div>
    </div>:
    error?<div className='flex p-4 font-raleway items-center justify-center'>
        <div className='flex flex-col items-center gap-y-4 text-center'>
            <h1>Something went wrong!<br></br>{error}<br/>Please refresh and try again!</h1>
            <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
            setCategory('')
                setDone(false)
                setError(false)
            }}>Ok</button>
            </div>
    </div>:
        <form className='flex flex-col justify-center items-center md:gap-x-4' onSubmit={(e)=>
            {
                e.preventDefault()
                addCategory()
            }
        }>
            <input type="text" className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'value={category} onChange={e=>setCategory(e.target.value)}/>
            <input type="submit" value="Add new Category" className='mt-4 py-1 px-2 bg-primary3 text-white w-48 mb-6 rounded-md'/>
        </form>
}
    </div>
  )
}
