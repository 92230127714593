import React from "react";
import { useState } from "react";
import { DatePicker,TimeRangeInput } from '@mantine/dates';
export default function DateForm(props) {
    const [bookDate, setBookDate] = useState(null);
    const [value, setValue] = useState(null);
    function setDate(){

        let dateData={
            date:bookDate.toDateString(),
            minTime:value[0].toLocaleTimeString(),
            maxTime:value[1].toLocaleTimeString(),
        }
        props.getDate(dateData);
    }
    return (
        <React.Fragment>
              <DatePicker placeholder="Pick date" label="Event date" withAsterisk onChange={(value)=>setBookDate(value)}/>
              <TimeRangeInput label="Appointment time" value={value} onChange={setValue} clearable />
              <button onClick={setDate} className='font-dosis bg-primary2 md:py-2 md:px-4 rounded-full text-white md:my-6  hover:text-primary2 hover:bg-white hover:border hover:border-primary2 py-2 px-3 text-xl my-6'>Finish</button>        
        </React.Fragment>
    );
};