import React from 'react'
import { useState,useEffect } from 'react'
import useAxios from '../api/useAxios'
import { Accordion, Loader } from '@mantine/core';
export default function BookingPage() {
    const api=useAxios()
    const [data,setData]=useState(null)
    const [isLoading,setIsLoading]=useState(true)
  useEffect(() => { 
    api.get('api/booking/').
    then(
        (response)=>{
          setData(response.data)
          setIsLoading(false)
          console.log('databooking',response.data)
        }
        )
      .catch((error)=>{
          console.log(error)
          console.log("error")
      })
  
},[]);
  return (
    <div className='py-6'>
        {
            isLoading?
            <div className='flex flex-col items-center justify-center gap-y-4'>
                <Loader/>
                <h1 className='text-center font-raleway'>Try relaoding the page if loading takes a long time</h1>
            </div>:

            (   
                <div className='font-raleway'>
                {data.map(booking=>(
                <Accordion defaultValue="all" >
                    <Accordion.Item value="list">
                    <Accordion.Control>
                        <div className='flex font-raleway items-center justify-between px-6'>
                            <h1>{booking.name.toUpperCase()}</h1>
                            <h1>{booking.date}</h1>
                        </div>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <div className='bg-tblack text-white'>
                            <table className='py-4 text-sm md:text-md md:w-[30%]'>
                                <tr className=''>
                                    <td className='py-2  pl-2'>Country</td>
                                    <td>{booking.country?booking.country:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Course</td>
                                    <td>{booking.course?booking.course:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Email</td>
                                    <td>{booking.email?booking.email:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Marital Status</td>
                                    <td>{booking.marital_status?booking.marital_status:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Phone</td>
                                    <td>{booking.phone?booking.phone:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Qualification</td>
                                    <td>{booking.qualification?booking.qualification:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Passed year</td>
                                    <td>{booking.year_passed?booking.year_passed:"Unspecified"}</td>
                                </tr>
                                <tr className=''>
                                    <td className='py-2 pl-2'>Desired Time</td>
                                    <td>{booking.time?booking.time:"Unspecified"}</td>
                                </tr>
                            </table>
                        </div>
                    </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
                ))}
                </div>
            )

        }
        
    </div>
  )
}
