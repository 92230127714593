import React from 'react';
import { motion } from 'framer-motion';
import {FaUserFriends}  from "react-icons/fa";
import {FaBookOpen}  from "react-icons/fa";

import {FaThumbsUp}  from "react-icons/fa";
import { HoverCard} from '@mantine/core';

export default function Features() {
  return (
    <React.Fragment>
         <div className='overflow-x-hidden hidden md:block'>
          <h1 className='md:text-5xl font-tittilium fond-extrabold text-center md:mb-16 text-xl mb-10'>We Offer</h1>
          <div className='flex items-center justify-center space-x-5 px-6 mb-32'>
            <HoverCard width={280} shadow="md" position='left' offset={100}>
              <HoverCard.Target>
                <motion.div className='md:h-32 md:w-32 rounded-full bg-primary3 flex items-center justify-center h-16 w-16'
                  initial={{x:'200%',scale:0.1}}
                  whileInView={{x:'0%',scale:1}}
                  transition={{duration:1,ease:'linear'}}
                  viewport={{once:true}}
                  >
                      <FaUserFriends className='text-white text-4xl'/>
                  </motion.div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                  <p className='font-dosis text-lg'>
                  We provide over-the-top counselling to help you make the right decisions so you reach the right place to solidify your future.
                  </p>
              </HoverCard.Dropdown>
            </HoverCard>
            <motion.h1 className='font-dosis md:text-3xl font-bold'
              initial={{x:'200%'}}
              whileInView={{x:'0%'}}
              transition={{duration:1,ease:'linear'}}
              viewport={{once:true}}
            >
                Expert counselling
            </motion.h1>
          </div>
          <div className='flex items-center justify-center space-x-5 px-6 mb-32 '>
            <motion.h1 className='font-dosis md:text-3xl font-bold'
              initial={{x:'-200%'}}
              whileInView={{x:'0%'}}
              transition={{duration:1,ease:'linear'}}
              viewport={{once:true}}
            >
                Best preparation classes
              </motion.h1>
             
              <HoverCard width={280} shadow="md" position='right' offset={100}>
              <HoverCard.Target>
                <motion.div className='md:h-32 md:w-32 rounded-full bg-primary3 flex items-center justify-center'
                  initial={{x:'-200%',scale:0.1}}
                  whileInView={{x:'0%',scale:1}}
                  transition={{duration:1,ease:'linear'}}
                  viewport={{once:true}}
                >
                    <FaBookOpen className='text-white text-4xl'/>
                </motion.div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                  <p className='font-dosis text-lg'>
                    Our instructors are one of the best in the country to help you breeze through your language tests.
                  </p>
              </HoverCard.Dropdown>
            </HoverCard>
          </div>

          <div className='flex items-center justify-center space-x-5 px-6 mb-32'>
            <HoverCard width={280} shadow="md" position='left' offset={100}>
              <HoverCard.Target>
                <motion.div className='md:h-32 md:w-32 rounded-full bg-primary3 flex items-center justify-center'
                  initial={{x:'200%',scale:0.1}}
                  whileInView={{x:'0%',scale:1}}
                  transition={{duration:1,ease:'linear'}}
                  viewport={{once:true}}
                  >
                      <FaThumbsUp className='text-white text-4xl'/>
                  </motion.div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                  <p className='font-dosis text-lg'>
                    Your chances of failing are slim to none with our expert visa processing.
                  </p>
              </HoverCard.Dropdown>
            </HoverCard>
            <motion.h1 className='font-dosis md:text-3xl font-bold'
              initial={{x:'200%'}}
              whileInView={{x:'0%'}}
              transition={{duration:1,ease:'linear'}}
              viewport={{once:true}}
            >
                High Success rate
            </motion.h1>
          </div>
          <div className='flex items-center justify-center space-x-5 px-6 mb-32 '>
            <motion.h1 className='font-dosis md:text-3xl font-bold'
              initial={{x:'-200%'}}
              whileInView={{x:'0%'}}
              transition={{duration:1,ease:'linear'}}
              viewport={{once:true}}
            >
                  Best Infrastructure
              </motion.h1>
             
              <HoverCard width={280} shadow="md" position='right' offset={100}>
              <HoverCard.Target>
                <motion.div className='md:h-32 md:w-32 rounded-full bg-primary3 flex items-center justify-center'
                  initial={{x:'-200%',scale:0.1}}
                  whileInView={{x:'0%',scale:1}}
                  transition={{duration:1,ease:'linear'}}
                  viewport={{once:true}}
                >
                    <FaBookOpen className='text-white text-4xl'/>
                </motion.div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                  <p className='font-dosis text-lg'>
                    We will give you nothing but the best resources on our journey together. 
                  </p>
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
     </div>
    </React.Fragment>
  )
}
