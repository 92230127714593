import React from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { motion } from 'framer-motion';
export default function PhoneCard() {
  return (
    <motion.div className='flex flex-col box-border md:h-48 md:w-96 border-2 p-0 shadow-md shadow-black h-32 w-72 md:mb-3 mb-4'
    initial={{x:'100%',scale:0.1}}
    animate={{x:'0%',scale:1}}
    transition={{duration:0.5}}
    > 
       <div className=' md:px-32 bg-primary3 md:py-2 text-center px-24 py-1' >
        
        <h1 className='font-bold text-white md:text-2xl font-hkg'>Call us</h1>
       </div>
       <div className='flex flex-col items-center font-hkg'>
        <a href="tel:9815994167"><h1 className=' md:text-lg my-1'>9806050657</h1></a>
        <a href="tel:9815994167"><h1 className=' md:text-lg my-1'>9861028744</h1></a>
        <a href="tel:9815994167"><h1 className=' md:text-lg my-1'>9852051425 (Tech Support)</h1></a>
        <a href="tel:9815994167"><h1 className=' md:text-lg my-1'></h1></a>
       </div>
       
       <div>

       </div>
    </motion.div>
  )
}
