import React from 'react'
import profilePlaceholder from '../../assets/images/profile-user.png'
export default function StudentProfilePreview(props) {
    const data=props.data
    const data1={
        name:"Harry Potter",
        address:"London,Beijing",
        country:"Australia",
        profile_picture:profilePlaceholder,
        checked:false,
        phone:"9815994167"
    }
  return (
    <div className='flex flex-col px-4 py-12 justify-center items-center font-raleway'>
 <body class="bg-gray-100">
  <div class="max-w-lg mx-auto my-10 bg-white rounded-lg shadow-md p-5">
    <img class="w-32 h-32 rounded-full mx-auto" src={data.profile_pic} alt="Profile picture"/>
    <h2 class="text-center text-2xl font-semibold mt-3">{data.name}</h2>
    <p class="text-center text-gray-600 mt-1">{data.address}</p>
    <p class="text-center text-gray-600 mt-1">Applying for {data.country}</p>
    <div class="flex justify-center mt-5">
      <a href="#" class="text-blue-500 hover:text-blue-700 mx-3 font-hkg">{data.phone}</a>
      
    </div>
  
  </div>
</body>
    </div>
  )
}