import React from "react";
import { motion } from "framer-motion";
export default function CountryDetails(props) {
  const data = props.data;
  console.log(data, "data");
  const franceDocuments=(
    <ul class="list-disc ml-6">
    <li>COST TO APPLY FRANCE</li>
    <ul class="list-disc ml-6">
        <li>It will cost around 12-15 lakhs in total</li>
    </ul>
    <li>INTAKE</li>
    <ul class="list-disc ml-6">
        <li>It has two intake</li>
        <ul class="list-disc ml-6">
            <li>September intake</li>
            <li>January intake</li>
        </ul>
    </ul>
    <li>PROCESSING</li>
    <ul class="list-disc ml-6">
        <li>Pay 120 Euro to college or university for documentation check and for SOP</li>
        <li>If the university accepts you, then it will send an agreement paper where it mentions a nonrefundable fee of the college (euro 350-500)</li>
        <li>Then, make NOC (No Objection Certificate)</li>
        <li>Send academic documents to the campus branch, and they will have a fixed appointment date for an interview</li>
        <li>If you pass the interview, then you have to send the college fee</li>
        <li>College will send Sponsorship</li>
    </ul>
    <li>DOCUMENTATION</li>
    <ul class="list-disc ml-6">
        <li>Financial documents (Income more than 12 lakhs)</li>
        <li>Bank balance (60 lakhs)</li>
        <li>Audit Report</li>
        <li>Academic documents</li>
        <li>Ward Document</li>
    </ul>
    <li>At last, send all documents to VFs office and wait for visa approval</li>
</ul>

  )
  const canadaDocuments=(
    <ul class="list-disc ml-6">
    <li>FOR Offer letter PROCESS</li>
    <ul class="list-disc ml-6">
        <li>All academic documents</li>
        <li>College recommendation letter</li>
        <li>Passport</li>
        <li>IELTS/PTE/TOFEL/GRE</li>
        <li>CV</li>
        <li>SOP (if required)</li>
        <li>Application fee may be required</li>
        <li>Get NOC (No Objection Certificate)</li>
    </ul>
    <li>DOCUMENTATION</li>
    <ul class="list-disc ml-6">
        <li>Ward</li>
        <ul class="list-disc ml-6">
            <li>Relationship verification certificate</li>
            <li>Annual income</li>
            <li>Tax clearance of annual income</li>
            <li>Address verification</li>
            <li>Birth certificate</li>
        </ul>
        <li>Citizenship Certificate of family members and relatives</li>
        <li>Property valuation</li>
        <li>Landownership certificate and their tax clearance certificate</li>
        <li>Education loan or fund from A level bank of Nepal which covers one-year tuition fee, living expenses, travel cost, and insurance of one year</li>
        <li>Financial documents with sources (annual income - 30 lakh)</li>
        <li>Police report</li>
        <li>SOP (Statement of Purpose)</li>
        <li>CA report</li>
        <li>Digital passport size photo</li>
    </ul>
</ul>

  )
  const ukDocuments=(
    <ul class="list-disc ml-6">
    <li>OFFER LETTER (there are two types of offer letter)</li>
    <ul class="list-disc ml-6">
        <li>Documents Required for unconditional Offer Request:</li>
        <ul class="list-disc ml-6">
            <li>All academic transcripts and certificates</li>
            <li>Passport</li>
            <li>Passport</li>
            <li>IELTS/TOEFL score</li>
            <li>2 recommendation letters from teachers</li>
            <li>Work experience letter to fulfill gaps if any</li>
            <li>Statement of Purpose</li>
            <li>CV</li>
        </ul>
        <li>After that, you will get conditional offer letter from university (you also get acceptance letter form and disposal form from college)</li>
        <ul class="list-disc ml-6">
            <li>Make No objection certificate</li>
            <li>Manage fund (1-year tuition fees + 9 months Living Cost) and hold it in the bank account for a minimum 28 days. If you are studying in Greater London*, you must have at least £1,265 per month of your course. If you are studying outside of London, you will have to show £1,015 per month of your course. Each dependent will be required to demonstrate maintenance of £6120 (£680 per month up to a maximum of 9 months)</li>
            <li>Pay tuition fees (1 year in case of Inside London OR Initial deposit of Universities of Outside London)</li>
            <li>Get a TB certificate from IOM.</li>
            <li>Prepare additional financial documents like Relationship certificates with sponsor, Income and property valuation.</li>
        </ul>
    </ul>
    <li>Apply for CAS AND TAKE APPOINTMENT FOR INTERVIEW</li>
    <ul class="list-disc ml-6">
        <li>Fees payment confirmation</li>
        <li>TB certificate</li>
        <li>Financial documents</li>
    </ul>
    <li>AFTER THIS PROCESS DO BIOMETRIC AND GIVE INTERVIEW FOR 20 MIN</li>
    <li>VISA LODGMENT AND WAIT FOR IT</li>
</ul>

  )
  const australiaDocuments=(
        <div className="">
          <ul class="list-disc ml-6">
            <li>Offer letter</li>
            <ul class="list-disc ml-6">
              <li>Academic documents till date</li>
              <li>IELTS/TOEFL/PTE Score</li>
              <li>Passport</li>
              <li>Work Experience (If any)</li>
              <li>University/College Application form</li>
              <li>
                Skype/phone interview may be conducted by university for offer
                letter (Offer letter processing time 2 days to 1 month)
              </li>
              <li>SOP (IF Required)</li>
              <li>CV (IF REQUIRED)</li>
            </ul>
            <li>VISA DOCUMENTS</li>
            <ul class="list-disc ml-6">
              <li>All Academic Certificates</li>
              <li>
                2 recent passport-size color photos with white background.
              </li>
              <li>IELTS/TOEFL Score</li>
              <li>
                Financial documents @ Educational Loan from A level bank of
                Nepal to cover 1-year college fee, one year living expenses
                (21041 AUD), 1 year travelling expenses and insurance cover by
                educational loan.
              </li>
              <li>Income sources with supporting documents (18lakhs above)</li>
              <li>
                Property Valuation with supporting documents (1crore above)
              </li>
              <li>Tax Clearance Certificate</li>
              <li>Relationship certificate with sponsors and family members</li>
              <li>Birth Certificate</li>
              <li>Police Report</li>
              <li>Citizenship Certificate (translated in English)</li>
              <li>Confirmation of Enrollment (Coe) from University/College</li>
              <li>Statement of Purpose (SOP)</li>
              <ul class="list-disc ml-6">
                <li>
                  If you are applying with a spouse, evidence of the ‘genuine
                  and continuing’ nature of your relationship with your spouse.
                  For example;
                </li>
                <ul class="list-disc ml-6">
                  <li>Marriage certificate</li>
                  <li>Marriage invitation card</li>
                  <li>Joint hotel booking</li>
                  <li>Flight booking/cinema tickets</li>
                  <li>Marriage video</li>
                  <li>Joint travel tickets, online gifts receipts</li>
                  <li>Relationship verification</li>
                  <li>Evidence of cohabitation</li>
                  <li>
                    Evidence of joint financial assets or liabilities (if
                    applicable) such as joint ownership of property or joint
                    bank accounts
                  </li>
                  <li>
                    Evidence of the social nature of the relationship such as
                    photos together in various places
                  </li>
                  <li>Statements from friend’s/family members</li>
                  <li>
                    Loan to cover dependent’s living, traveling cost which is
                    AUD 6515 per year with 2000 AUD Traveling cost.
                  </li>
                </ul>
                <li>Visa Application charge (Demand Draft)</li>
                <li>Employment certificate of applicant</li>
                <li>Documents related to job experience of applicant.</li>
              </ul>
            </ul>
            <li>Secondary applicant documents</li>
            <ul class="list-disc ml-6">
              <li>Passport all pages certified</li>
              <li>Citizenship</li>
              <li>Coe</li>
              <li>IMMI visa grant</li>
              <li>Study letter</li>
              <li>Offer letter</li>
              <li>Cig ID card</li>
              <li>Academic Transcript</li>
              <li>Tax invoice of 1</li>
              <li>Tax invoice of 2</li>
            </ul>
          </ul>
        </div>
      )
      const documents={
        'Australia':australiaDocuments,
        'UK':ukDocuments,
        'Canada':canadaDocuments,
        'France':franceDocuments
      }
  return (
    <div className="">
      <div className="my-6 px-6 md:flex md:h-half">
        <motion.p
          className="font-raleway text-justify md:text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={{ duration: 1 }}
        >
          {data.intro}
        </motion.p>
      </div>
      <div className="my-3 px-6 md:h-half">
        <motion.h1
          className="font-raleway md:text-2xl font-extra-bold pt-3 pb-6 text-xl text-justify"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 100 }}
          transition={{ duration: 1 }}
        >
          Why study in {data.title}?
        </motion.h1>
        <motion.p
          className="font-raleway  md:text-lg py-3 px-6 text-justify"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 100 }}
          transition={{ duration: 2 }}
        >
          {data.whyText}
        </motion.p>
        <motion.h1
          className=" font-raleway md:text-2xl font-extra-bold pt-3 pb-6 text-xl"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 100 }}
          transition={{ duration: 1 }}
        >
          Eligibility
        </motion.h1>
        <motion.ul
          className="font-raleway  md:text-lg py-3 px-6 list-disc text-justify"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 100 }}
          transition={{ duration: 2 }}
        >
          {data.eligibility.map((point) => (
            <li>{point}</li>
          ))}
        </motion.ul>
        <motion.h1
          className=" font-raleway md:text-2xl font-extra-bold pt-3 pb-6 text-xl text-justify"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 100 }}
          transition={{ duration: 1 }}
        >
          Documents required
        </motion.h1>
        {
            documents[data.title]?documents[data.title]:<div><h1 className="">To be updated soon</h1></div>
        }
      </div>
    </div>
  );
}
