import React from 'react'
import TeamSection from '../components/aboutUs/team'
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
export default function TeamPage() {
  return (
    <div className='overflow-x-hidden'>
      <Navbar/>
      <TeamSection/>
      <Footer/>
     </div>
  )
}
