import React from "react";
import { Link } from "react-router-dom";
const Button = (props) => {
  return (
    <Link to={props.url}>
     <button className="bg-primary3 text-white  px-6 py-2 rounded-full font-dosis " onClick={props.clikFn}>
      {props.name}
    </button></Link>
  );
};

export default Button;