
import { Modal} from '@mantine/core';
import { Link } from 'react-router-dom';
import '../../index.css'
import { Stepper,LoadingOverlay} from '@mantine/core';
import { useState } from 'react';
import ModalForm from './modalForm';
import ModalForm2 from './modalForm2';
import DateForm from './dateForm';
import meetImage from '../../assets/images/meet.png';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import axios from '../../api/axios';
export default function BookModal(props) {
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed,setIsFailed]=useState(true)
  const [basicInfo, setBasicInfo] = useState(null);
  const [apDate, setApDate] = useState(null);
  const [academicInfo, setAcademicInfo] = useState(null);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
//send api request to post the booking infiormation
  function book(data){
    axios.post('api/book/',data)
    .then(
      (response)=>{
        console.log(response)
        setIsLoading(false);
        setIsFailed(false)
        nextStep();
      }
      )
    .catch((error)=>{
      console.log(error)
      setIsLoading(false);
   
      nextStep()
    })
  }
  //funtion to send email using emailJS
  /*function sendEmail(data){
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID,data, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
    .then((result) => {
        setIsLoading(false);
        nextStep();
    }, (error) => {
        setIsLoading(false);
        console.log(error.text);
    });
  }*/

  //below are functions that collect data from forms on each step and save them as states
  //the steps also change after soft validation of forms in Modalforms
  function  form1SubmitHandler(values){
    setBasicInfo(values);
    nextStep();
  }
  function  form2SubmitHandler(values){
    setAcademicInfo(values);
    console.log(values,basicInfo);
    nextStep();
  }
  function getApDate(values){
    setApDate(values);
    const data={
      name:basicInfo.name,
      email:basicInfo.email,
      phone:basicInfo.phNumber,
      country:basicInfo.country,
      marital_status:basicInfo.mStatus,
      qualification:academicInfo.qualification,
      year_passed:academicInfo.passedYear,
      course:academicInfo.Course,
      date:apDate.date,
      time:`${apDate.minTime} to ${apDate.maxTime}`

  }
  console.log('data',data)
    /*const message=`
    Name: ${basicInfo.name}
    Emali: ${basicInfo.email}
    Phone Number: ${basicInfo.phNumber}
    Country: ${basicInfo.country}
    Marital Status: ${basicInfo.mStatus}
    qualification: ${academicInfo.qualification}
    Year Passed: ${academicInfo.passedYear}
    Course: ${academicInfo.Course}
    Date: ${apDate.date}
    Time:${apDate.minTime} to ${apDate.maxTime}
    `;
    console.log("From message",message);
    const emailData={
      name:basicInfo.name,
      message:message
    }*/
    setIsLoading(true);
    book(data);
    //sendEmail(emailData);
  }
 
  return (
    <>
      <Modal
        size='xl'
        opened={props.opened}
        onClose={() => {
          setActive(0);
          props.closeModal();
        }}
        overlayBlur={4}
        title="Book an Appointment"
        transition='skew-up'
        exitTransition='skew-up'
        transitionDuration={500}
        exitTransitionDuration={500}
        transitionTimingFunction='ease'
        closeOnClickOutside={false}

      >
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
     
        <div className='w-full h-full bg-white font-raleway'>
        <Stepper active={active}  breakpoint="sm" size='sm'  color="red">
        <Stepper.Step description="Enter basic details">
              <ModalForm submitHandle={form1SubmitHandler}/>
        </Stepper.Step>
        <Stepper.Step  description="Academic details">
            <ModalForm2 submitHandle={form2SubmitHandler}/>
        </Stepper.Step>
        <Stepper.Step  description="Pick a date">
          <DateForm getDate={getApDate}/>
        </Stepper.Step>
        <Stepper.Completed>
          <div className='md:flex'> 
            <motion.img src={meetImage} alt="" className='md:h-64 md:w-auto'
            initial={{scale:0.1}}
            animate={{scale:1}}
            transition={{duration:1}}
            />
            {isFailed?
            (<div>
              <h1 className='text-primary2 md:text-xl font-raleway font-bold text-center md:my-6'>Something went wrong</h1>
              <p className='text-primary font-raleway'> Please make sure You have entered valid data.</p>
            </div>):(
            <div className='text-center w-full'>
              <motion.h1 className=' md:text-xl font-raleway font-bold text-center md:my-6'
              initial={{y:'-100%'}}
              animate={{y:'0%'}}
              transition={{duration:1}}
              >Booked!!</motion.h1>
              <motion.p className='text-primary font-raleway'
               initial={{opacity:0}}
               animate={{opacity:100}}
               transition={{duration:2}}>
                You will be further notified via email or a direct call from our staff.<br/>
                you may close the modal and continue browsing.
              </motion.p>
              
            </div>)}
          </div>
        </Stepper.Completed>
      </Stepper>
     
        </div>
      </Modal>
    </>
  );
}