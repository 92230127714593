import jwtDecode from 'jwt-decode';
import {createContext,useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from '../api/axios';

const AuthContext=createContext();


export const AuthProvider=({children})=>{
    const navigate = useNavigate();
    
    const [user,setUser]=useState(localStorage.getItem('authToken')?jwtDecode(JSON.parse(localStorage.getItem('authToken')).access):null);
    const [authTokens,setAuthTokens]=useState(localStorage.getItem('authToken')?JSON.parse(localStorage.getItem('authToken')):null);
    
    const loginUser=(data)=>{
        let jsonData=JSON.stringify(data)
        setAuthTokens(data)
        setUser(jwtDecode(data.access))
        localStorage.setItem('authToken',jsonData);
        navigate('/transition')
    }
    const logout=()=>{
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authToken')
        navigate('/')
    }
    let contextData={
        loginUser:loginUser,
        user:user,
        logout:logout,
        setUser:setUser,
        authTokens:authTokens
    }
    
    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext;