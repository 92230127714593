import React from "react";
import ProfileForm from "../profileForm";

export default function StudentProfile(props) {
  const profileData = props.data;

  return (
    <React.Fragment>
      {profileData.checked ? (
        <div className="flex flex-col px-4 py-12 justify-center items-center font-raleway">
          <body class="bg-gray-100">
            <div class="max-w-lg mx-auto my-10 bg-white rounded-lg shadow-md p-5">
              <img
                class="w-32 h-32 rounded-full mx-auto"
                src={profileData.profile_pic}
                alt="Profile picture"
              />
              <h2 class="text-center text-2xl font-semibold mt-3">
                {profileData.name}
              </h2>
              <p class="text-center text-gray-600 mt-1">
                {profileData.address}
              </p>
              <div class="flex justify-center mt-5">
                <a
                  href="#"
                  class="text-blue-500 hover:text-blue-700 mx-3 font-hkg"
                >
                  {profileData.phone}
                </a>
              </div>
              <div class="mt-5">
                <h1 className="text-center">
                  Applying for {profileData.country}
                </h1>
              </div>
            </div>
          </body>
        </div>
      ) : (
        <ProfileForm id={profileData.id} />
      )}
    </React.Fragment>
  );
}
