import React from 'react'
import logo1 from '../../assets/images/naaer-logo.png';
import logo2 from '../../assets/images/Nacci-Logo.png';
import logo3 from '../../assets/images/anaa-logo.png';
import logo4 from '../../assets/images/ierin-logo.jpg';
import greylogo1 from '../../assets/images/naeergrey.png';
import greylogo2 from '../../assets/images/naccigrey.png';
import greylogo3 from '../../assets/images/anaagrey.png';
import greylogo4 from '../../assets/images/ieringrey.png';
export default function Affiliations() {
  return (
    <div className='my-8'>
      <h1 className='font-raleway px-6 text-headmd md:text-center md:text-2xl'>Our Affiliates</h1>
        <div className='flex justify-between py-4 my-4 px-6 md:px-8'>
            <img src={greylogo1} alt="" className='md:h-16 h-12'/>
            <img src={greylogo4} alt="" className='md:h-16 h-12'/>
            <img src={greylogo3} alt="" className='md:h-16 h-12'/>
            <img src={greylogo2} alt="" className='md:h-16 h-12'/>
        </div>
    </div>
  )
}
