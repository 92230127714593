import React from "react";
import { useState } from "react";

import useAxios from "../../api/useAxios";
export default function StudentRegisterForm() {
  const [username, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password1, setpassword1] = useState("");
  const [email, setEmail] = useState("");
  const [password2, setPassword2] = useState("");
  const [emailOkay, setEmailOkay] = useState(false);
  const [passwordMatched, setPasswordMatched] = useState(false);
  const [passwordStrong, setPasswordStrong] = useState(false);
  const [userNameOkay, setUserNameOkay] = useState(false);
  const [firstNameOkay, setFirstNameOkay] = useState(false);
  const [lastNameOkay, setLastNameOkay] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(null);

  const passwordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  let api = useAxios();
  const allFieldsOkay = () => {
    console.log(
      "all",
      userNameOkay,
      firstNameOkay,
      lastNameOkay,
      passwordStrong,
      passwordMatched
    );
    return (
      userNameOkay &&
      firstNameOkay &&
      lastNameOkay &&
      passwordStrong &&
      passwordMatched &&
      emailOkay
    );
  };
  const usernamechange = (p1) => {
    if (p1.length > 2) {
      setUserName(p1);
      
      setUserNameOkay(true);
      setFormValidated(allFieldsOkay());
    } else {
      setUserNameOkay(false);
      setFormValidated(false);
    }
  };
  const firstNameChane = (p1) => {
    if (p1.length > 0) {
      setFirstName(p1);
      setFirstNameOkay(true);
      setFormValidated(allFieldsOkay());
    } else {
      setFirstNameOkay(false);
      setFormValidated(false);
    }
  };
  const lastNameChange = (p1) => {
    if (p1.length > 0) {
      setLastName(p1);
      setLastNameOkay(true);
      setFormValidated(allFieldsOkay());
    } else {
      setLastNameOkay(false);
      setFormValidated(false);
    }
  };
  const password1change = (p1) => {
    if (passwordRegex.test(p1)) {
      setPasswordStrong(true);
      setpassword1(p1);

      console.log("password is strong");
      setFormValidated(allFieldsOkay());
    } else {
      setPasswordStrong(false);
      setFormValidated(false);
      console.log("not strong");
    }
  };
  const emailChange = (p1) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(p1)) {
      setEmailOkay(true);
      setEmail(p1);

      console.log("password is strong");
      setFormValidated(allFieldsOkay());
    } else {
      setEmailOkay(false);
      setFormValidated(false);
      console.log("not strong");
    }
  };
  const password2change = (p2) => {
    if (p2 === password1) {
      setPasswordMatched(true);
      setPassword2(p2);
      console.log("matched");
      setFormValidated(allFieldsOkay());
    } else {
      setPasswordMatched(false);
      console.log("unmatched");
      setFormValidated(false);
    }
  };
  const submitForm = () => {
    api
      .post("/api/student-user/", {
        username: username,
        password1: password1,
        password2: password2,
        email: email,
        first_name: firstName,
        last_name: lastName,
      })
      .then((response) => {
        setSubmitted(true);
        setError(false)
        setDone(true)
      })
      .catch((error) => {
        if(error.response.data.detail){
          setError(error.response.data.detail)
        }
        else if(error.response.data.email){
          if(error.response.data.email==='This field must be unique.'){
            setError('A user with this email already exists')
          }
          else{
            setError('something is wrong with email service')
          }
        }
        else{
          setError('server side error')
        }
      });
  };
  return (
    <div>
      {
        done? <div className='flex justify-center items-center'>
        <div className='flex flex-col items-center gap-y-4  px-4 py-2' >
        <h1 className='font-raleway'>New student added</h1>
        <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
            setUserName('')
            setFirstName('')
            setLastName('')
            setEmail('')
            setpassword1('')
            setPassword2('')
           
            setDone(false)
            window.location.reload(true)
        }}>Ok</button>
        </div>
    </div>:
    error?<div className='flex p-4 font-raleway items-center justify-center'>
    <div className='flex flex-col items-center gap-y-4 text-center'>
        <h1>Something went wrong!<br></br>{error}<br/>Please refresh and try again!</h1>
        <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
            setUserName('')
            setFirstName('')
            setLastName('')
            setEmail('')
            setpassword1('')
            setPassword2('')
            setError(false)
            window.location.reload(true)
        }}>Ok</button>
        </div>
</div>:
      <form
        className="flex flex-col justify-center md:pl-16 font-raleway"
        onSubmit={(e) => {
          e.preventDefault();

          if (allFieldsOkay()) {
            submitForm();
          } else {
            setError("Please enter valid data")
          }
        }}
      >
        <label htmlFor="">Username</label>
        <input
          onChange={(e) => usernamechange(e.target.value)}
          type="text"
          name="username"
          className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
        />
        {userNameOkay ? (
          <div></div>
        ) : (
          <p className="font-raleway text-xs px-6 text-primary2">
            Username must be greater than 4 characters
          </p>
        )}
        <label htmlFor="">Email</label>
        <input
          type="text"
          onChange={(e) => emailChange(e.target.value)}
          name="password1"
          className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
        />
        {emailOkay ? (
          <div></div>
        ) : (
          <p className="font-raleway text-xs px-6 text-primary2">
            Enter valid email
          </p>
        )}
        <label htmlFor="">First Name</label>
        <input
          onChange={(e) => firstNameChane(e.target.value)}
          type="text"
          name="firstname"
          className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
        />
        <label htmlFor="">Last Name</label>
        <input
          onChange={(e) => lastNameChange(e.target.value)}
          type="text"
          name="lastname"
          className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
        />
        <label htmlFor="">Password</label>
        <input
          type="password"
          onChange={(e) => password1change(e.target.value)}
          name="password1"
          className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
        />
        {passwordStrong ? (
          <div></div>
        ) : (
          <p className="md:w-72 font-raleway text-xs px-6 text-primary2">
            Password must contain, lowercase, Uppercase, number, special
            character and must be greater than 8 characters.
          </p>
        )}
        <label htmlFor="">Confirm Password</label>
        <input
          type="password"
          onChange={(e) => password2change(e.target.value)}
          name="password1"
          className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
        />
        {passwordMatched ? (
          <div></div>
        ) : (
          <p className="font-raleway text-xs px-6 text-primary2">
            Passwords must match
          </p>
        )}
        <input
          className={`w-24 bg-primary3 text-center text-white rounded-md p-1 mt-2 opacity-100 md:mb-4 cursor-pointer`}
          type="submit"
          value="Submit"
        />
      </form>
}
    </div>
  );
}
