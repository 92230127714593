import React from 'react'
import { useState } from 'react'
import { Toaster,toast } from 'react-hot-toast'
import useAxios from '../api/useAxios'
import { Loader } from '@mantine/core'
export default function StoryForm() {
    const [name,setName]=useState('')
    const [country,setCountry]=useState('')
    const [testimonial,setTestimonial]=useState('')
    const [fileSelected,setFileSelected]=useState(false)
    const [file,setFile]=useState(null)
    const [done,setDone]=useState(false)
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState(null)
    const api=useAxios()
    const postStory=(data)=>{
        console.log('posting data')
        api.post('/add-story/',data,
        {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }
        ).
        then(response=>{
            setDone(true)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            console.log(error)
        })
    }
    const onFileChange=(file)=>{
        if(!file){
            setFileSelected(false)
            
            
        }
        else{
            setFileSelected(true)
            setFile(file)
            console.log(file,'file')
        }
    }
   
  return (
    
    <div className='flex justify-center'>
        <Toaster/>
        {
            done?
            <div className='flex justify-center items-center'>
                <div className='flex flex-col items-center gap-y-4  px-4 py-2' >
                <h1 className='font-raleway'>Story Added</h1>
                <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
                    setName('')
                    setTestimonial('')
                    setFile(null)
                    setDone(false)
                }}>Ok</button>
                </div>
            </div>:
        loading?<div className='flex justify-center items-center'><Loader/></div>:
        error?
        <div className='flex p-4 font-raleway items-center justify-center'>
        <div className='flex flex-col items-center gap-y-4 text-center'>
            <h1>Something went wrong!<br></br>{error}<br/>Please refresh and try again!</h1>
            <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
                setName('')
                setTestimonial(false)
                setError(false)
            }}>Ok</button>
            </div>
    </div>:
        <form className="flex flex-col w-48 font-raleway" onSubmit={(e)=>
        {
            e.preventDefault()
            console.log('on submit')
            if(file && name.length>1){
                console.log(name,country,file,testimonial)
                setLoading(true)
                const data={
                    name:name,
                    country:country,
                    image:file,
                    text:testimonial
                }
                postStory(data)
            }
            else{
                console.log('not submitted')
                setCountry('')
                setName('')
                setError('Data not valid')
                setFile(null)
                
            }
        }
        }>
            <label htmlFor="">Name</label>
            <input type="text" value={name} className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1' onChange={(e)=>setName(e.target.value)}/>
            <label htmlFor="">Country</label>
            <select name="country" id="" onChange={e=>setCountry(e.target.value)} className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'>
                <option value="">Select a country</option>
                <option value="UK">UK</option>
                <option value="USA">USA</option>
                <option value="Japan">Japan</option>
                <option value="Australia">Australia</option>
                <option value="Cyprus">Cyprus</option>
                <option value="NZ">New Zealand</option>
            </select>
            <label htmlFor="">Testimonial</label>
            <textarea type="text" value={testimonial} className='border-2 border-primary3 rounded-md w-64 mt-2 mb-4 px-2 py-1' onChange={(e)=>setTestimonial(e.target.value)}/>
            <label htmlFor="file">Image</label>
            <input type="file" accept="image/png, image/jpeg" name="file" onChange={e=>onFileChange(e.target.files[0])}/>
            <div className='flex justify-center'>
                    <input type='submit' value="Add a story" className='mt-4 py-1 px-2 bg-primary3 text-white w-48 mb-6 rounded-md'/>
                </div>
        </form>
    
}
    </div>
  )
}




