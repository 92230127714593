import React from 'react'
import { useState,useEffect } from 'react'
import { FaTrash } from "react-icons/fa";
import useAxios from '../api/useAxios'
import uniLogo from '../assets/images/naeergrey.png'
import UniForm from '../pages/uniForm';
export default function UniverityPage() {
    const api=useAxios()
    const [data,setData]=useState(null)
    const [isLoading,setIsLoading]=useState(true)
  useEffect(() => { 
    api.get('/university').
    then(
        (response)=>{
          setData(response.data)
          setIsLoading(false)
          console.log('datauni',response.data)
        }
        )
      .catch((error)=>{
          console.log(error)
          console.log("error")
      })
  
},[]);
  return (
    <div>
        <div className=''>
          <UniForm/>
        </div>
            <h1 className='text-center font-raleway text-xl font-bold mb-6 '>List of Universities</h1>
            <div className='px-4 py-4 h-96 overflow-y-scroll'>
                {isLoading?<div>
                    <h1 className='text-center'>Loading</h1>
                </div>:
                    data.map(uni=>(
                        <div className='flex flex-col items-center my-2'>
                            <img src={uni.logo} alt="" className='w-16 h-12'/>
                            <h1 className='font-raleway text-center text-sm mt-4'>{uni.name}</h1>
                            <FaTrash className='mt-2 mb-6 hover:text-primary2 text-lg' onClick={()=>console.log(uni.name)}/>
                        </div>
                    ))
                }
            </div>
    </div>
  )
}
