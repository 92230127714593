import React from 'react'
import { FaAngleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
export default function Service(props) {
    const title=props.data.title
    const directionLeft=props.data.leftDirection
    const image=props.data.img
    const link=props.data.link
    const linkName=props.data.linkName
    const description=props.data.description
    const titleComponent=title.map(name=>name.highlight===false?(
        <span className='text-tblack'>{name.word+" "}</span>
    ):
    (
        <span className='text-primary3'>{name.word+" "}</span>
    )
    )
console.log(titleComponent)
  return (
    <div className='w-screen overflow-hidden bg-white'>
        <div className='flex bg-primary3 bg-opacity-10 mx-6 relative mt-24 h-32'>
                  
            {
                directionLeft===true?(
                <React.Fragment>
                    <img src={image} alt="" className='-translate-y-24 -translate-x-4 w-half absolute z-110 '/>
                    <div className='font-hkg font-bold text-cardhead relative left-36 top-10 whitespace-nowrap md:text-xl '>
                        {titleComponent}
                    </div>
                </React.Fragment>
                ):(
                <React.Fragment>
                    <div className='font-hkg font-bold text-cardhead relative left-8 top-10 whitespace-nowrap md:text-xl '>
                       {titleComponent}
                    </div>
                    <img src={image} alt="" className='-translate-y-24 translate-x-52 absolute z-110 w-half md:right-48'/>
                </React.Fragment>
                )
                }
           
        </div>
        <div className='relative z-200 bg-white overflow-hidden'>
            <p className='px-8 text-bodysm font-hkg font-normal text-justify my-4 md:text-lg'>
                {description}
            </p>
            <Link to={link}>
                <div className='flex items-center px-8 justify-end'>
                    <h1 className='text-primary3 text-headmd'>{linkName}</h1>
                    <FaAngleRight className='text-md text-primary3'/>
                </div>
            </Link>
        </div>
        
    </div>
  )
}
