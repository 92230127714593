import React from "react";
import { useState } from "react";
import { Toaste, Toaster, toast } from "react-hot-toast";
import profilePlaceholder from "../assets/images/profile-user.png";
import useAxios from "../api/useAxios";
export default function ProfileForm(props) {
  let api = useAxios();
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [file, setFile] = useState(null);
  const [profilePic, setProfilePic] = useState(profilePlaceholder);
  const submitToServer = () => {
    
    const requestData = {
      country: country,
      phone: phone,
      address: address,
      image: file,
    };
    api
      .put("api/student/profile/", requestData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success("changes saved");
        window.location.reload(true);
      })
      .catch((error) => {
        toast.error("something went wrong");
      });
  };
  const onFileChange = (file) => {
    if (!file) {
      setFileSelected(false);
      
    } else {
      setFileSelected(true);
      setFile(file);
      setProfilePic(URL.createObjectURL(file));
      URL.revokeObjectUR(file);
      
    }
  };

  return (
    <React.Fragment>
      <h1 className="font-hkg text-center text-xl mb-6 font-bold">
        Please Enter these details to complete your Profile
      </h1>
      <div className="flex justify-center ">
        <Toaster />
        <form
          className="flex flex-col w-48 font-raleway items-center"
          onSubmit={(e) => {
            e.preventDefault();
            
            if (file && address.length > 5 && phone.length > 9) {
              submitToServer();
            } else {
              
              setPhone("");
              setCountry("");
              setAddress("");
              setFile(profilePlaceholder);
              toast.error("Fill valid data");
            }
          }}
        >
          <img
            class="w-32 h-32 rounded-full mx-auto border-2 mb-4"
            src={profilePic}
            alt="Profile picture"
          />
          <label htmlFor="" className="">
            Address
          </label>
          <input
            type="text"
            value={address}
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
            onChange={(e) => setAddress(e.target.value)}
          />
          <label htmlFor="" className="">
            Country
          </label>
          <select
            name="country"
            id=""
            onChange={(e) => setCountry(e.target.value)}
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
          >
            <option value="UK">Select country to apply</option>
            <option value="UK">UK</option>
            <option value="USA">USA</option>
            <option value="Japan">Japan</option>
            <option value="Australia">Australia</option>
            <option value="Cyprus">Cyprus</option>
            <option value="NZ">New Zealand</option>
            <option value="France">France</option>
            <option value="Canada">Canada</option>
          </select>
          <label htmlFor="">Phone</label>
          <input
            type="tel"
            value={phone}
            className="border-2 border-primary3 rounded-md w-64 mt-2 mb-4 px-2 py-1"
            onChange={(e) => setPhone(e.target.value)}
          />
          <label htmlFor="file">Profile Picture</label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            name="file"
            onChange={(e) => onFileChange(e.target.files[0])}
          />
          <div className="flex justify-center">
            <input
              type="submit"
              value="Save Changes"
              className="mt-4 py-1 px-2 bg-primary3 text-white w-48 mb-6 rounded-md cursor-pointer"
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
