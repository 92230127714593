import React, { useEffect,useState } from 'react'
import useAxios from '../../api/useAxios';
import { FaUserAltSlash } from "react-icons/fa";
import { Modal,LoadingOverlay} from '@mantine/core';
import StaffDeleteModal from '../dialogs/deleteStaffDialog';
export default function StaffList() {
    let api=useAxios();
    const [staffData,setStaffData]=useState(null)
    const [open,setOpen]=useState(false)
    const [deleteData,setDeleteData]=useState(null)
    const closeModal=()=>setOpen(false);
    const deleteStaffLocally=(deleteId)=>{
      let newList=staffData.filter((staff)=>(staff.id!=deleteId))
      setStaffData(newList)
      console.log('deleted')
    }
    useEffect(() => { 
        api.get('api/staff-users/').
        then(
            (response)=>{
              setStaffData(response.data)
              console.log('datastaff',response.data)
            }
            )
          .catch((error)=>{
              console.log(error)
              console.log("error")
          })
      
    },[]);
  return (
    <div>
      {staffData?(
        <div className='flex flex-col items-center py-6 h-half overflow-y-scroll'>
          <table className='w-[90%]'>
            <tr className='bg-tblack text-white font-hkg py-2 text-center border-1  '>
              <th>Username</th>
              <th>Full Name</th>
              <th>Action</th>
            </tr>
            {staffData.map((staff)=>(
              <tr className='font-raleway text-center border-tblack border-2 border-collapse py-2'>
                <td>{staff.username}</td>
                <td>{staff.first_name+" "+staff.last_name}</td>
                <td className='flex items-center justify-center py-2'> 
                  <FaUserAltSlash onClick={()=>{
                  setDeleteData({'staff':staff.username,'id':staff.id})
                  setOpen(true)
        
                  }}/>
                </td>
            </tr>
          ))}
            
          </table>
          
          <StaffDeleteModal opened={open} closeModal={closeModal}  deleteData={deleteData?deleteData:{'data':'none'}} deleteLocally={deleteStaffLocally}/>
        </div>
      )
      :(
        <h1 className='font-raleway'>No Staffs </h1>
      )
      }
    </div>
  )
}
