import React from 'react'
import { Modal} from '@mantine/core';
import useAxios from '../../api/useAxios';
import { useState } from 'react'

export default function FeedbackModal(props) {
    const feedbackData=props.feedback
  return (
    <React.Fragment>
        <Modal
        size='md'
        opened={props.opened}
        onClose={() => {
          props.closeModal();
        }}
        overlayBlur={2}
        title={"Feedback by "+feedbackData.staff}
        transition='skew-up'
        exitTransition='skew-up'
        transitionDuration={500}
        exitTransitionDuration={500}
        transitionTimingFunction='ease'
        closeOnClickOutside={false}
        >
        <div className='font-raleway mt-8'>
            <p>{feedbackData.text}</p>
           
        </div>
          
        </Modal>
    </React.Fragment>
  )
}
