import React from "react";
import axios from "axios";

export default function DocumentCard(props) {
  const doc = props.doc;

  
  return (
    <div className="w-full h-96 mt-4 font-raleway md:h-screen">

        <embed src={doc.file_link} frameborder="0" height="100%" width="100%" type="application/pdf"/>
  
    </div>
  );
}
