import React from 'react'
import { useState } from 'react'
import { Toaste,toast } from 'react-hot-toast'
import useAxios from '../api/useAxios'
export default function UniForm() {
    const [name,setName]=useState('')
    const [link,setLink]=useState('')
    const [fileSelected,setFileSelected]=useState(false)
    const [file,setFile]=useState(null)
    const [loading,setLoading]=useState(false)
    const [done,setDone]=useState(false)
    const [error,setError]=useState(null)
    const api=useAxios()
    const postUniversity=(data)=>{
        api.post('/add-university/',data,
        {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }
        ).
        then(response=>{
            setDone(true)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            setError(error.response.data.detail)
        })
    
    }
    const onFileChange=(file)=>{
        if(!file){
            setFileSelected(false)
            console.log(file)
            
        }
        else{
            setFileSelected(true)
            setFile(file)
            console.log(file,'file')
        }
    }
    const onSubmit=(e)=>{
        e.preventDefault()
        console.log('on submit',file)
        if(fileSelected && name.length>1 && link.length>5){
            console.log(name,link,file)
            const data={
                name:name,
                link:link,
                image:file
            }
            setLoading(true)
            postUniversity(data)
        }
        else{
            console.log('empty')
            setLink('')
            setName('')
            setFile(null)
            
        }
    }
  return (
    
    <div className='flex justify-center'>
        {done? <div className='flex justify-center items-center'>
                <div className='flex flex-col items-center gap-y-4  px-4 py-2' >
                <h1 className='font-raleway'>University Added</h1>
                <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
                    setName('')
                    setLink('')
                    setFile(null)
                    setDone(false)
                }}>Ok</button>
                </div>
            </div>:
            error?
            <div className='flex p-4 font-raleway items-center justify-center'>
        <div className='flex flex-col items-center gap-y-4 text-center'>
            <h1>Something went wrong!<br></br>{error}<br/>Please refresh and try again!</h1>
            <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
                setName('')
                setLink('')
                setFile(null)
                setError(false)
            }}>Ok</button>
            </div>
    </div>:
        <form className="flex flex-col w-48 font-raleway" onSubmit={(e)=>
        {
            e.preventDefault()
            console.log('on submit')
            if(file && name.length>1 && link.length>5){
                const data={
                    name:name,
                    link:link,
                    image:file
                }
                setLoading(true)
                postUniversity(data)
            }
            else{
                setLink('')
                setName('')
                setFile(null)
                setLoading(false)
                setError('Fill valid data')
            }
        }
        }>
            <label htmlFor="">Name</label>
            <input type="text" value={name} className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1' onChange={(e)=>setName(e.target.value)}/>
            <label htmlFor="">Link</label>
            <input type="text" value={link} className='border-2 border-primary3 rounded-md w-64 mt-2 mb-4 px-2 py-1' onChange={(e)=>setLink(e.target.value)}/>
            <label htmlFor="file">Logo</label>
            <input type="file" accept="image/png, image/jpeg" name="file" onChange={e=>onFileChange(e.target.files[0])}/>
            <div className='flex justify-center'>
                    <input type='submit' value="Add a University" className='mt-4 py-1 px-2 bg-primary3 text-white w-48 mb-6 rounded-md'/>
                </div>
        </form>
}
    </div>
  )
}



