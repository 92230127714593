import React from "react";
import Navbar from "../components/navbar/navbar";
import { Tabs } from "@mantine/core";
import { FaUserCircle, FaFile, FaCog,FaRegListAlt } from "react-icons/fa";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../context/authContext";
import StudentProfile from "../components/profile/studentProfile";
import useAxios from "../api/useAxios";
import StudentDocuments from "../components/docs/studentDocuments";
import profilePlaceholder from "../assets/images/profile-user.png";
import Footer from "../components/footer/footer";
import Settings from "../components/profile/settings";
export default function StudentPage() {
  const mockData = {
    name: "Harry Potter",
    address: "London",
    country: "Australia",
    profile_pic: profilePlaceholder,
    checked: false,
    phone: "888888888",
  };
  let api = useAxios();
  let { user } = useContext(AuthContext);
  const [data, setData] = useState(null);
  useEffect(() => {
    api
      .get("api/student/profile/")
      .then((response) => {
        setData(response.data);
        console.log("studentProfile", response.data);
      })
      .catch((error) => {
        console.log(error);
        console.log("error");
      });
  }, []);
  return (
    <React.Fragment>
      <Navbar />
      <div className="font-raleway px-12 mt-6">
        <Tabs color="teal" defaultValue="second">
          <Tabs.List>
            <Tabs.Tab value="first">
              <div className="flex justify-between items-center gap-x-2">
                <FaUserCircle />
                <h1>Profile</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="second" color="blue">
              <div className="flex justify-between items-center gap-x-2">
                <FaFile />
                <h1>My Documents</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="third" color="blue">
              <div className="flex justify-between items-center gap-x-2">
                <FaCog />
                <h1>Settings</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="fourth">
              <div className="flex justify-between items-center gap-x-2">
                <FaRegListAlt />
                <h1>Terms of Service</h1>
              </div>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="first" pt="xs">
            <StudentProfile data={data ? data : mockData} />
          </Tabs.Panel>

          <Tabs.Panel value="second" pt="xs">
            <StudentDocuments />
          </Tabs.Panel>
          <Tabs.Panel value="third" pt="xs">
            <Settings />
          </Tabs.Panel>
          <Tabs.Panel value="fourth" pt="xs">
            <div class="container mx-auto py-8 px-4 lg:px-8 font-hkg">
              <h1 class="text-3xl font-bold mb-8">
                Terms of Service - Detail Education Consultancy Website
              </h1>

              <h2 class="text-xl font-bold mb-4">1. Acceptance of Terms</h2>
              <p class="mb-4">
                By accessing or using the Website, you acknowledge that you have
                read, understood, and agree to be bound by these Terms. If you
                do not agree to these Terms, you may not use the Website or our
                services.
              </p>

              <h2 class="text-xl font-bold mb-4">2. Service Description</h2>
              <p class="mb-4">
                Our Website provides education consultancy services to assist
                individuals in their educational endeavors. These services may
                include but are not limited to guidance, advice, and assistance
                in various educational matters.
              </p>

              <h2 class="text-xl font-bold mb-4">3. Use of Documents</h2>
              <p class="mb-4">
                a. Document Upload: As part of our services, users may have the
                option to upload and submit documents, including but not limited
                to transcripts, resumes, and personal statements. By uploading
                documents to the Website, you grant us a non-exclusive,
                right to distribute such documents to concerned parties for the
                purpose of providing our services to you.
              </p>
              <p class="mb-4">
                b. Document Deletion: While we strive to maintain the security
                and confidentiality of user documents, we do not provide primary
                storage for documents. Therefore, it is your responsibility to
                ensure that you retain copies of any important documents
                submitted to us. We reserve the right to delete all user
                documents from our servers after the completion of the service
                period or as otherwise determined by us.
              </p>

              <h2 class="text-xl font-bold mb-4">4. User Responsibilities</h2>
              <p class="mb-4">
                a. Accuracy and Legality: You represent and warrant that any
                documents you submit to the Website are accurate, lawful, and do
                not infringe upon any third-party rights, including but not
                limited to copyright, trademark, or privacy rights. You are
                solely responsible for the content of the documents you provide.
              </p>
              <p class="mb-4">
                b. Indemnification: You agree to indemnify and hold us harmless
                from any claims, losses, liabilities, damages, expenses, or
                costs (including reasonable attorneys' fees) arising out of or
                related to your use of the Website, including any violation of
                these Terms or any applicable laws or regulations.
              </p>

              <h2 class="text-xl font-bold mb-4">5. Contact Information</h2>
              <p class="mb-4">
                If you have any questions or concerns about these Terms or the
                Website, please contact us.
              </p>

              <p class="text-sm">
                By using our Education Consultancy website, you acknowledge that
                you have read, understood, and agree to be boundby these Terms
                of Service.
              </p>
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
      <Footer />
    </React.Fragment>
  );
}
