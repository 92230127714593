import React, { useEffect } from "react";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import { useLocation, useNavigate } from "react-router-dom";
import CountryHead from "../components/countryHead/countryHead";
import CountryDetails from "../components/countryDetails/countryDetails";
import InViewCTA from "../components/inViewCTA";

export default function Countrypage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, image } = location.state || { data: null, image: null };

  useEffect(() => {
    if (!data) {
      navigate('/');
    }
    window.scrollTo(0, 0);
  }, [data, navigate]);

  return (
    <div>
      <Navbar />
      {data && (
        <div className="  ">
          <CountryHead title={data.title} image={image} />
          <CountryDetails data={data} />
        </div>
      )}
      {data && <InViewCTA title={data.title} />}
      <Footer />
    </div>
  );
}
