import React from 'react'
import { FaEnvelope } from "react-icons/fa";
import { motion } from 'framer-motion';
export default function EmailCard() {
    
  return (
    <motion.div className='flex flex-col box-border md:h-auto md:w-96 border-2 p-0 shadow-md shadow-black h-32 w-72 md:mb-3 mb-4'
    initial={{x:'-100%',scale:0.1}}
    animate={{x:'0%',scale:1}}
    transition={{duration:0.5}}
    > 
       <div className='flex justify-between md:px-32 bg-primary3 md:py-2 items-center px-24 py-1' >
        <FaEnvelope className='text-white'/>
        <h1 className='font-bold text-white md:text-2xl font-hkg'>Email us</h1>
       </div>
       <div className='flex flex-col items-center font-hkg'>
        <a href="info@detaileducation.edu.np"><h1 className='md:my-2 md:text-lg my-1'>info@detaileducation.edu.np</h1></a>
        <a href="mailto:9815994167"><h1 className='md:my-2 md:text-lg my-1'>admission.detailedu@gmail.com</h1></a>
        <a href="mailto:9815994167"><h1 className='md:my-2 md:text-lg my-1'>sbnsim81@gmail.com (Tech Support)</h1></a>
       </div>
       
       <div>

       </div>
    </motion.div>
  )
}
