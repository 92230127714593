import React from 'react'
import { Modal} from '@mantine/core';
import { useState } from 'react';
import {Toaster,toast } from 'react-hot-toast';
import useAxios from '../../api/useAxios';
export default function RegisterModal(props) {
  const [username,setUserName]=useState('')
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')
  const [password1,setpassword1]=useState('')
  const [password2,setPassword2]=useState('')
  const [passwordMatched,setPasswordMatched]=useState(false)
  const [passwordStrong,setPasswordStrong]=useState(false)
  const [userNameOkay,setUserNameOkay]=useState(false)
  const [firstNameOkay,setFirstNameOkay]=useState(false)
  const [lastNameOkay,setLastNameOkay]=useState(false)
  const [formValidated,setFormValidated]=useState(false)
  const [submitted,setSubmitted]=useState(false)
  const [error,setError]=useState(null)
  const passwordRegex=new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  let api=useAxios()
  const reloadPage=()=>{
   
    window.location.reload(true);
    toast.success('Added a new Staff User')
  }

  const allFieldsOkay=()=> {
    console.log('all',userNameOkay,firstNameOkay,lastNameOkay,passwordStrong,passwordMatched)
    return (userNameOkay && firstNameOkay && lastNameOkay && passwordStrong && passwordMatched)
  }
  const usernamechange=(p1)=>{
    if(p1.length>2){
      setUserName(p1)
      setUserNameOkay(true)
      setFormValidated(allFieldsOkay())
    }
    else{
      setUserNameOkay(false)
      setFormValidated(false)
    }
  }
  const firstNameChane=(p1)=>{
    if(p1.length>0){
      setFirstName(p1)
      setFirstNameOkay(true)
      setFormValidated(allFieldsOkay())
    }
    else{
      setFirstNameOkay(false)
      setFormValidated(false)
    }
  }
  const lastNameChange=(p1)=>{
    if(p1.length>0){
      setLastName(p1)
      setLastNameOkay(true)
      setFormValidated(allFieldsOkay())
    }
    else{
      setLastNameOkay(false)
      setFormValidated(false)
    }
  }
  const password1change=(p1)=>{
    if(passwordRegex.test(p1)){
      setPasswordStrong(true)
      setpassword1(p1)
      
      console.log('password is strong')
      setFormValidated(allFieldsOkay())
    }
    else{
      setPasswordStrong(false)
      setFormValidated(false)
      console.log('not strong')
    }
  }
  const password2change=(p2)=>{
    if(p2===password1){
      setPasswordMatched(true)
      setPassword2(p2)
      console.log('matched')
      setFormValidated(allFieldsOkay())
      
    }
    else{
      setPasswordMatched(false)
      console.log('unmatched')
      setFormValidated(false)
    }
    
  }
  const submitForm=()=>{
    api.post('/api/staff-user/',{
      username:username,
      password1:password1,
      password2:password2,
      first_name:firstName,
      last_name:lastName
    }).
    then(
      (response)=>(
      reloadPage()
  
      ))
    .catch((error)=>{
        console.log(error)
        console.log("error")
       
    })
  }
  return (
    <React.Fragment>
        <Modal
        size='xl'
        opened={props.opened}
        onClose={() => {
          props.closeModal();
        }}
        overlayBlur={4}
        title="Add new staff"
        transition='skew-up'
        exitTransition='skew-up'
        transitionDuration={500}
        exitTransitionDuration={500}
        transitionTimingFunction='ease'
        closeOnClickOutside={false}
        >
            <div>
              <Toaster/>
              {submitted?(
              <div className='flex justify-center items-center'>
                <h1 className='font-raleway text-2xl'> Successfull</h1>
              </div>)
                :(<form className='flex flex-col font-raleway' onSubmit={(e)=>
                {
                  e.preventDefault()
                  if(allFieldsOkay()){
                    submitForm()
                  }
                  else{
                    toast.error('Please enter valid data')
                  }
                }
                }>
                  <label htmlFor="">Username</label>
                  <input onChange={(e)=>usernamechange(e.target.value)} type="text" name='username' className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'/>
                  {userNameOkay?<div></div>:<p className='font-raleway text-xs px-6 text-primary2'>Username must be greater than 4 characters</p>}
                  <label htmlFor="">First Name</label>
                  <input onChange={(e)=>firstNameChane(e.target.value)} type="text" name='firstname' className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'/>
                  <label htmlFor="">Last Name</label>
                  <input onChange={(e)=>lastNameChange(e.target.value)} type="text" name='lastname' className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'/>
                  <label htmlFor="">Password</label>
                  <input type="password" onChange={(e)=>password1change(e.target.value)} name='password1' className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'/>
                  {passwordStrong?<div></div>:<p className='font-raleway text-xs px-6 text-primary2'>Password must contain, lowercase, Uppercase, number, special character and must be greater than 8 characters.</p>}
                  <label htmlFor="">Confirm Password</label>
                  <input type="password" onChange={(e)=>password2change(e.target.value)} name='password1' className='border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1'/>
                  {passwordMatched?<div></div>:<p className='font-raleway text-xs px-6 text-primary2'>Passwords must match</p>}
                  <input  className={`w-24 bg-primary3 text-center text-white rounded-md p-1 mt-2 opacity-100`} type="submit" value="Submit"/>
                </form>)
                }
            </div>
        </Modal>
    </React.Fragment>
  )
}
