import React, { useState } from 'react'
import jwtDecode from 'jwt-decode';
import { motion } from 'framer-motion';
import loginGif from '../assets/images/loginMan.gif';
import { useNavigate } from 'react-router-dom';

export default function TransitionPage() {
    const [user,setUser]=useState(localStorage.getItem('authToken')?jwtDecode(JSON.parse(localStorage.getItem('authToken')).access):null);
    const navigate=useNavigate()
    function goTo(){
        if(user.isStaff){
                if(user.isAdmin){
                    navigate("/admin")
                }
                else{
                    navigate("/staff")
                }
        }
        else{
            navigate("/student")
        }
    }
 
    
    console.log(user)
  return (
    <div className='md:flex'>
        <motion.img src={loginGif} alt="" className='md:h-72 h-48 mt-24 md:mt-6' 
        initial={{scale:0.1}}
        animate={{scale:1}}
        transition={{duration:1}}
        />
        <div>
            <motion.h1 className='font-dosis md:text-7xl md:mt-48 md:ml-24 font-bold text-primary2 text-2xl mt-6 ml-6'
            initial={{opacity:0}}
            animate={{opacity:100}}
            transition={{duration:2}}
            >
                Logging in as,
            </motion.h1>
            <motion.h1 className='font-dosis md:text-8xl md:mt-12 md:ml-72 font-bold text-primary2 text-3xl mt-6 ml-32'
             initial={{opacity:0}}
             animate={{opacity:100}}
             transition={{duration:2,delay:1}}
             onAnimationComplete={goTo}
            >
                {user.isStaff?(user.isAdmin?"Admin":'Staff'):"Student"}
            </motion.h1>
        </div>
        
    </div>
  )
}
