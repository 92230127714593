import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import { links } from './links';
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { uiData } from '../../data/uidata';

export default function NavLinks() {
  const [heading, setHeading] = useState('')
  const getCountryData=(countryName)=>{
   for(let i=0;i<uiData.mapData.countryMarkerData.length;i++){
    if(countryName===uiData.mapData.countryMarkerData[i].title) return uiData.mapData.countryMarkerData[i].description;
   }
  }
  const getCountryImage=(countryName)=>{
    for(let i=0;i<uiData.mapData.countryMarkerData.length;i++){
     if(countryName===uiData.mapData.countryMarkerData[i].title) return uiData.mapData.countryMarkerData[i].image;
    }
   }
  return (
    <React.Fragment> 
      {
        //submenu for every link(links are imported from links.jsx)
        links.map(link=>(
          <div>
            <div className='px-3 text-left md:cursor-pointer group'>
              <h1 className='py-7 flex justify-between items-center md:pr-0 pr-5 font-dosis text-lg ' onClick={()=>{heading!==link.name?setHeading(link.name):setHeading('')}}>
                {link.name}
                <span className='text-xl md:mt-1 md:ml-2 md:hidden'>  
                  {heading===link.name?<FaAngleUp/>:<FaAngleDown/>}
                </span>
                {
                  //check if link has submenu specified
                link.submenu && (
                   
                    <div className={`absolute z-50 top-20 p-3.5 justify-items-center items-center bg-white hidden group-hover:md:grid h-48 w-72 group-hover:md:grid-cols-2 md:box-border md:border-b-2 md:border-r-2 md:border-primary2`}>
               
                      {
                      //iterate through sublinks
                      //In case of countries the data is extracted here and sent via location state
                      //hasState checks whether a link has data to send to the route
                      link.sublinks.map((mylink)=>(
                        <li className='z-15 text-sm my-2.5'>
                          {link.hasState?<Link to={mylink.link} className="hover:text-primary2" state={{ data:getCountryData(mylink.name),image:getCountryImage(mylink.name)}}>{mylink.name}</Link>
                          :<Link to={mylink.link} className="hover:text-primary2">{mylink.name}</Link>}
                          
                        </li>
                      ))}
                    </div>
              
                )}
              </h1>
            </div>
            {/* mobile view for sublinks */}
            <div className='md:hidden'>
              {link.sublinks.map((mylink)=>(
                <div className={`
                 ${heading===link.name?'md:hidden':'hidden'}
                `}>
                  <li className='py-3 pl-14'>
                  {link.hasState?<Link to={mylink.link} className="hover:text-primary2 font-dosis text-md" state={{ data:getCountryData(mylink.name),image:getCountryImage(mylink.name)}}>{mylink.name}</Link>
                          :<Link to={mylink.link} className="hover:text-primary2 font-dosis text-md">{mylink.name}</Link>}
                    
                  </li>                  
                </div>
              ))}
            </div>
          </div>
        ))
      }
    </React.Fragment>
  )
}
