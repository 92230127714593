import React, { useEffect, useState } from "react";
import { universityData } from "../../data/uniData";
import UniSliderMarquee from "./uniSliderMarquee";
import uniAxios from "../../api/uniAxios";
import { Loader } from "@mantine/core";

export default function UnishowShowCase() {
  const { response, loading, error } = uniAxios();

  useEffect(() => {}, [response]);
  return (
    <div className="mt-8 md:mb-6 md:mt-12">
      <p className="px-6 text-center tracking-wide text-tblack font-raleway text-headlg md:text-2xl">
        <span className="">Our</span>
        <span className=" text-primary3"> Universities</span>
      </p>
      <div className="">
        <div className="flex py-6 gap-x-8 overflow-x-scroll scrollbar-hide ">
          {loading ? (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <UniSliderMarquee data={response} />
          )}
        </div>
      </div>
    </div>
  );
}
