import React from 'react'
import { motion } from 'framer-motion';
import {FaAngleDoubleDown} from "react-icons/fa";
export default function AboutHero() {
  return (
    <div>
        <section>
            <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24 h-screen">
                <div class="flex flex-col w-full md:mb-12 text-center items-center">
               
                <motion.h1 class="max-w-5xl font-raleway text-2xl font-bold leading-none tracking-tighter text-primary3 md:text-2xl lg:text-4xl lg:max-w-7xl md:mt-0 mt-32"
                initial={{y:'-100%',scale:0.1}}
                animate={{y:'0%',scale:1}}
                transition={{duration:1}}
                >
                    COME ABOARD THE SHIP OF DREAMS <br class="hidden lg:block"/>
                    SET SAIL TOWARDS EXCELLENCE
                </motion.h1>

                <motion.p class="font-raleway max-w-xl mx-auto md:mt-8 md:text-xl leading-relaxed text-center text-gray-500 mt-24"
                 initial={{y:'100%',scale:0.1}}
                 animate={{y:'0%',scale:1}}
                 transition={{duration:1}}
                >We are dedicated to guide you towards your dream through detailed handling of all the required process in helping you choose your destination and reach it.</motion.p>
                <motion.div
                initial={{scale:0.5}}
                animate={{scale:1}}
                transition={{repeat:'Infinity',repeatType: "reverse",duration:0.5}}
                >
                <FaAngleDoubleDown className='text-6xl text-primary3 md:mt-36 mt-24 hidden md:block'/>
                </motion.div>
                
                </div>
            </div>
        </section>
    </div>
  )
}
