import React from 'react'
import Marquee from "react-fast-marquee";
export default function UniSliderMarquee(props) {
  return (
    <Marquee 
    gradient={false}
    speed={80}
    >{props.data?
      props.data.map((uni)=> 
        <a href={uni.link} target="_blank" className=''>
          <div className='w-24 mx-6'>
            <img src={uni.logo} alt="" className='w-full h-16'/>
          </div>
        </a>
      ):<div className='flex justify-center items-center my-4 py-4'><h1 className=''>Will be updated soon</h1></div>
    }
         
    </Marquee>
  )
}
