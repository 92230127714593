import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
export default function CountryCard(props) {
  const country = props.country;
  return (
    <React.Fragment>
      <div className="px-6 my-2">
        <div className="h-32">
          <h1 className="relative  z-110 top-8 left-2 font-hkg text-headlg text-white">
            {country.title}
          </h1>
          <img
            src={country.image}
            alt=""
            className="min-w-full h-32 rounded-sm"
          />
        </div>

        <div className="">
          <p className="px-8 text-bodysm font-hkg font-normal text-justify mt-10"></p>
          <Link
            to="/country"
            state={{ image: country.image, data: country.description }}
          >
            <div className="flex items-center px-7 justify-end">
              <h1 className="text-primary3 text-headmd">CHECK DETAILS</h1>
              <FaAngleRight className="text-md text-primary3" />
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
