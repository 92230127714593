import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,BrowserRouter
} from "react-router-dom";
import Homepage from './pages/homepage';
import ErrorPage from "./pages/error-page";
import Countrypage from './pages/countrypage';
import ContactUs from './pages/contactUs';
import AboutUs from './components/aboutUs/aboutUs';
import TrainingIelts from './pages/trainingIelts';
import TrainingPte from './pages/trainingPte';
import TeamPage from './pages/teamPage';
import TrainingToefl from './pages/trainingToefl';
import LoginPage from './pages/loginpage';
import AdminRoute from './components/privateRoute/adminRoute';
import { AuthProvider } from './context/authContext';
import AdminPage from './pages/admin';
import TransitionPage from './pages/transitionPage';
import StaffRoute from './components/privateRoute/staffRoute';
import StaffPage from './pages/staff';
import StudentRoute from './components/privateRoute/studentRoute';
import StudentPage from './pages/student';
import UniForm from './pages/uniForm';
import StudentPreview from './pages/studentPreview';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/country",
    element: <Countrypage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <ContactUs/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/aboutUs",
    element:<AboutUs/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/ielts",
    element: <TrainingIelts/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pte",
    element: <TrainingPte/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/toefl",
    element: <TrainingToefl/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/team",
    element: <TeamPage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <AuthProvider><LoginPage/></AuthProvider>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/transition",
    element: <TransitionPage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/form",
    element: <UniForm/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin",
    element: <AuthProvider>
              <AdminRoute>
                <AdminPage/>
              </AdminRoute>
            </AuthProvider>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/staff",
    element: <AuthProvider>
      <StaffRoute>
        <StaffPage/>
      </StaffRoute>
    </AuthProvider>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/student-preview",
    element: <AuthProvider>
      <StaffRoute>
        <StudentPreview/>
      </StaffRoute>
    </AuthProvider>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/student",
    element: <AuthProvider>
      <StudentRoute>
        <StudentPage/>
      </StudentRoute>
    </AuthProvider>,
    errorElement: <ErrorPage />,
  },
  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />

  </React.StrictMode>
);


