import React from 'react'
import Figure from '../../assets/images/stepFigure.svg'
export default function Steps() {
  return (
    <div className='my-16 md:mb-16 md:mt-2'> 
        <p className='px-6 text-center tracking-wide text-tblack relative z-90 font-raleway text-headlg md:text-2xl md:pb-8'>
            <span className=''>Empowering your educational journey </span>
            <span className=' text-primary3'>one step at a time</span>
        </p>
        <div className='flex justify-center'>
            <img src={Figure} alt="" className='my-10 w-screen md:scale-90'/> 
        </div>
        
    </div>
  )
}
