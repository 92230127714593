import React from 'react'
import image1 from '../../assets/images/wonder.jpg';
import { motion } from 'framer-motion';

import Features from './features';
import Testimonials from '../testimonials/testimonials';
import PhotoSection from './photoSection';
export default function AboutBody() {
  return (
    <React.Fragment>
     
     <Features/>
     <PhotoSection/>
     {/*<Testimonials/>*/}
    </React.Fragment>
  )
}
