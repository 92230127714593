import React, { useEffect, useState } from "react";
import useAxios from "../../api/useAxios";
import { FaUserAltSlash,FaUserAlt,FaRegEye,FaTrash } from "react-icons/fa";
import StaffDeleteModal from "../dialogs/deleteStaffDialog";
import { useNavigate } from 'react-router-dom';
import StudentDeleteModal from "../dialogs/studentDeleteModal";
export default function StudentTable() {
  const navigate=useNavigate()
  let api = useAxios();
  const [studentData, setStudentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openStudentDeleteModal, setOpenStudentDeleteModal] = useState(false);
  const [deleteDataStudent, setDeleteDataStudent] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const closeModal = () => setOpen(false);
  const closeStudentDeleteModal=()=>setOpenStudentDeleteModal(false)
  const deleteStudentLocally = (deleteId) => {
    let newList = studentData.filter((student) => student.id != deleteId);
    setStudentData(newList);
    console.log("deleted");
  };
  useEffect(() => {
    api
      .get("api/student/profiles/")
      .then((response) => {
        setStudentData(response.data);
        console.log("datastudent", response.data);
      })
      .catch((error) => {
        console.log(error);
        console.log("error");
      });
  }, []);
  return (
    <div>
      {studentData ? (
        <div className="flex flex-col items-center py-6 h-half overflow-y-scroll md:h-96">
          <table className="w-[90%]">
            <tr className="bg-tblack text-white font-hkg py-2 text-center border-1  ">
              <th></th>
              <th>Full Name</th>
              <th>Action</th>
            </tr>
            {studentData.map((student) => (
              <tr className="font-raleway text-center border-tblack border-2 border-collapse py-2 ">
                <td className="flex justify-center items-center h-20">
                  {student.profile_pic?<img src={student.profile_pic} alt="" className="h-10 w-10 rounded-full"/>:<FaUserAlt className="text-2xl"/>}
                </td>
                <td>{student.name}</td>
                <td className="flex items-center justify-center gap-x-4">
                  <h1 className="text-md hover:text-primary3 cursor-pointer"
                    onClick={() => {
                      navigate('/student-preview',{state:{profile:student}})
                    }}>
                      View
                  </h1> 
                  <FaTrash className="text-lg hover:text-primary2 cursor-pointer"
                    onClick={() => {
                     setDeleteDataStudent({id:student.id,student:student.name})
                     setOpenStudentDeleteModal(true)
                    }}
                  />
                </td>
              </tr>
            ))}
          </table>

          <StudentDeleteModal
            opened={openStudentDeleteModal}
            closeModal={closeStudentDeleteModal}
            deleteData={deleteDataStudent? deleteDataStudent : { data: "none" }}
          />
        </div>
      ) : (
        <h1 className="font-raleway">No Students </h1>
      )}
    </div>
  );
}
