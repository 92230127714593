import React from 'react'
import { useRef,useState } from 'react'
import { useInView } from "framer-motion"
import { Dialog} from '@mantine/core';
import Button from './Button';
import BookModal from './hero/modal';

//use an empty div at the bottom, to use as reference as to open a pop-up for CTA
export default function InViewCTA(props) {
    const [openModal, setOpenModal] = useState(false);
    const closeModal=()=>setOpenModal(false);
    const ref = useRef(null)
    const isInView = useInView(ref)
  return (
    <React.Fragment>
    <div ref={ref}></div> 
        <Dialog
        opened={isInView}
        size="lg"
        radius="md"
        transition={'skew-down'}
        shadow='md'
        >
        <h1 className=' font-raleway text-sm md:py-3 md:font-bold md:px-1 px-6 mb-3'>Do you think {props.title} is your dream destination?<br/> Book an appointment with us to make it reality.</h1>
        <Button clikFn={()=>setOpenModal(true)} name={"Book an appointment"}/>
        </Dialog>
        <BookModal opened={openModal} closeModal={closeModal}/>
  </React.Fragment>
  )
}
