import React from 'react'
import itImage from '../../assets/images/IT.jpg';
import cdImage from '../../assets/images/CD.jpg';
import odImage from '../../assets/images/OD.jpg';
import StaffCard from './staffCard';
import DirectorsWords from './directorsWords';
export default function TeamSection(){
	const staffData=[
		{
			name:'SABIN SIMKHADA',
			position:"CTO",
			image:itImage,
		},
		{
			name:'RUDRA RAYA',
			position:"Head of Canada",
			image:cdImage,
		},
		{
			name:'KIRAN SIMKHADA',
			position:"Business Development Officer",
			image:odImage,
		},
		
	]
  return (
    <div>
		<DirectorsWords/>
        <section className="py-6 dark:bg-gray-800 dark:text-gray-100">
	<div className="container flex flex-col items-center justify-center p-4 mx-auto sm:p-10">
		<p className="p-2 text-sm font-medium tracking-wider text-center uppercase  md:text-3xl">Our team</p>
	
		<div className="flex flex-row flex-wrap-reverse justify-center mt-8">
			
			{staffData.map((staff)=>(
				<StaffCard data={staff}/>
			))}			
		</div>
	</div>
</section>
    </div>
  )
}
