import React from 'react'

export default function Cta(props) {
  return (
    <div>
        <button className="bg-primary3 text-white w-full font-hkg text-btlg rounded-sm h-14 px-4 my-8 md:w-72 ">
            {props.buttonName}
        </button>
    </div>
  )
}
