import React from 'react'
import { register } from 'swiper/element/bundle';
import SuccessCard from './successCard';
// register Swiper custom elements
register();
export default function StoryCarousel(props) {
    const data=props.data
    const cards=data.map(student=> 
      <swiper-slide><SuccessCard data={student}/></swiper-slide>
    )
  return (
    <div className=''>
      <swiper-container  
      slides-per-view="1" speed="500" loop="true" css-mode="true" pagination="true" navigation="true"
      style={{
        "--swiper-pagination-color": "#447FD9",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "12px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
        "--swiper-navigation-size":"30px",
        "--swiper-navigation-color":"#343A42",
        "--swiper-navigation-top-offset":"32%"
      }}> 
        {cards}
      </swiper-container>
    </div>
  )
}
