import React from "react";
import auFlag from "../../assets/images/auflag.png";
import testImg from "../../assets/images/service1.png";
import p1Img from "../../assets/images/std1.png";
import StoryCarousel from "./storyCarousel";
import useAxios from "../../api/storyAxios";
import { useEffect, useState } from "react";
import { Loader } from "@mantine/core";
export default function SuccessStories() {
  const { response, loading, error } = useAxios();
  const [storyData, setData] = useState([]);
  const data = [
    {
      flagImg: auFlag,
      image: p1Img,
      name: "RESHMA KHATRI",
      country: "Australia",
      text: "Aliquid voluptas odio veniam suscipit rem consequatur animi unde rerum. Molestiae dolorem animi expedita ut velit. Possimus blanditiis facilis ducimus rerum. Non veniam nostrum voluptatem consequatur.",
    },
    {
      flagImg: auFlag,
      image: testImg,
      name: "ROSHAN SHRESHTHA",
      country: "Canada",
      text: "Aliquid voluptas odio veniam suscipit rem consequatur animi unde rerum. Molestiae dolorem animi expedita ut velit. Possimus blanditiis facilis ducimus rerum. Non veniam nostrum voluptatem consequatur.",
    },
  ];
  useEffect(() => {
    if (response !== null) {
      setData(response);
      console.log("success story", storyData);
    }
  }, [response]);
  return (
    <div>
      <p className="px-6 text-center tracking-wide text-tblack relative z-90 font-raleway text-headlg md:text-2xl">
        <span className="">Witness our</span>
        <span className="text-primary3"> Success stories!</span>
      </p>
      <div className="my-2">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (storyData.length?<div><StoryCarousel data={storyData} /></div>:<div className='flex justify-center items-center my-4 py-4'><h1 className=''>Will be updated soon</h1></div>
          
        )}
      </div>
    </div>
  );
}
