import React from "react";
import { FaFileAlt, FaComment, FaCommentSlash } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import axios from "../../api/axios";
import useAxios from "../../api/useAxios";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader } from "@mantine/core";
import DocumentDeleteModal from "../dialogs/deleteDocument";
import { Tooltip, Indicator, Accordion } from "@mantine/core";
import FeedbackModal from "../dialogs/feedbackModal";
import DocViewModal from "./docViewModel";
export default function StudentDocuments() {
  const navigate = useNavigate();
  let api = useAxios();
  const [open, setOpen] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openDocModal, setOpenDocModal] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const [deleteId, setDeleteId] = useState();
  const [categories, setCategories] = useState([]);
  const [documents, setDocuments] = useState();
  const closeModal = () => setOpen(false);
  const closeFeedback = () => setOpenFeedback(false);
  const closeDocView = () => setOpenDocModal(false);
  const openDeleteModal = (id) => {
    setDeleteId({ id: id });
    setOpen(true);
  };
  const getDocuments = () => {
    api
      .get("api/student/student-documents/")
      .then((response) => {
        setDocuments(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  };
  const getCategories = () => {
    axios
      .get("api/student/document-categories/")
      .then((response) => {
        setCategories(response.data);
        console.log("categories", response.data);
      })
      .catch((error) => console.log(error));
  };
  const postDocument = (data) => {
    api
      .post("api/student/document/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast
          .success("Document uploaded successfully")
          .then(window.location.reload(true));
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getCategories();
    getDocuments();
  }, []);

  const deleteFile = (id) => {
    console.log(id);
  };
  return (
    <div className="px-4 py-12 overflow-x-scroll font-raleway divide-y-2 md:w-screen md:flex md:divide-y-0 ">
      <Toaster />
      <div className="w-full h-72 overflow-y-scroll md:w-3/5 md:h-84">
        <table className="w-full">
          {documents ? (
            documents.map((file) => (
              <tr className="flex justify-around shadow-[0_3px_10px_rgb(0,0,0,0.2)] py-2 my-1">
                <td className="flex justify-center items-center">
                  <Tooltip label="click to view document">
                    <a target="_blank" href={file.file_link}>
                      <FaFileAlt className="text-xl md:text-4xl text-primary1 cursor-pointer" />
                    </a>
                  </Tooltip>
                </td>
                <td className="flex justify-center items-center">
                  <h1 className="text-sm md:text-md">{file.category}</h1>
                </td>
                <td className="flex justify-center items-center">
                  <h1 className="text-sm md:text-md">
                    {file.verified
                      ? "Verified by " + file.verified_by
                      : "Not approved"}
                  </h1>
                </td>
                <td className="flex justify-center items-center">
                  {file.feedback ? (
                    <Indicator>
                      <FaComment
                        className="cursor-pointer"
                        onClick={() => {
                          setFeedback({
                            text: file.feedback,
                            staff: file.feedback_by,
                          });
                          setOpenFeedback(true);
                        }}
                      />
                    </Indicator>
                  ) : (
                    <Tooltip label="No feedbacks">
                      <FaCommentSlash className="cursor-pointer" />
                    </Tooltip>
                  )}
                </td>
                <td className="flex justify-center items-center">
                  <FaTrash
                    className="text-xl text-primary2 cursor-pointer"
                    onClick={() => openDeleteModal(file.id)}
                  />
                </td>
                <DocumentDeleteModal
                  opened={open}
                  closeModal={closeModal}
                  id={deleteId ? deleteId : { id: "none" }}
                />
                <DocViewModal
                  opened={openDocModal}
                  closeModal={closeDocView}
                  link={fileLink ? fileLink : "none"}
                />
                <FeedbackModal
                  opened={openFeedback}
                  closeModal={closeFeedback}
                  feedback={
                    feedback ? feedback : { text: "none", staff: "none" }
                  }
                />
              </tr>
            ))
          ) : (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          )}
        </table>
      </div>
      <div className=" mt-5 md:flex md:flex-col md:items-center  md:w-2/5 md:mt-1">
        <h1 className="text-center font-bold mt-4">Upload a File</h1>
        <form
          className="flex flex-col font-raleway"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = {
              filename: e.target[0].value,
              category: e.target[1].value,
              file: e.target[2].files[0],
            };
            console.log(formData.file.type);
            if(formData.file.type==="application/pdf" && formData.file.size< 15728640){
              // postDocument(formData);
              console.log(formData.file.size)
            }
            else{
              alert("Only PDF files below 15mb are allowed")
            }
          
          }}
        >
          <label htmlFor="fileanme">File Name</label>
          <input
            type="text"
            name="filename"
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
          />
          <label htmlFor="category">Category</label>
          <select
            required
            name="category"
            id=""
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1 bg-white"
          >
            {categories ? (
              categories.map((category) => (
                <option value={category.name}>{category.name}</option>
              ))
            ) : (
              <option value="loading">Loading</option>
            )}
          </select>
          <label htmlFor="file">File( Pdf files below 15mb only )</label>
          <input type="file" name="file" required accept=".pdf"/>
          <div className="flex justify-center">
            <Tooltip lable="Please refresh the page if document doesn't submit"><input
              type="submit"
            
              className="mt-4 py-1 px-2  bg-primary3 text-white w-32 rounded-md cursor-pointer"
              value="Submit"
            /></Tooltip>
          </div>
        </form>
      </div>
    </div>
  );
}
