import React, { useState } from 'react'
import Navbar from '../components/navbar/navbar'
import axios from '../api/axios';
import {Toaster,toast } from 'react-hot-toast';
import { useContext } from 'react';
import AuthContext from '../context/authContext';
import { Loader } from '@mantine/core';
export default function Loginpage() {
    let [isLoading,setIsLoading]=useState(false)
    let {loginUser}=useContext(AuthContext)
    function login(e){
        e.preventDefault();
        if(e.target.username.value==='' | e.target.password.value===''){
            toast.error("Fields cannot be empty")
        }
        else{
            setIsLoading(true)
            let data={
                username:e.target.username.value,
                password:e.target.password.value
            }
            axios.post('api/token/',data)
            .then(
              (response)=>{
                setIsLoading(false)
                console.log("logged in")
                toast.success('Successfully logged in!')
                loginUser(response.data)
              }
              )
            .catch((error)=>{
                setIsLoading(false)
                console.log(error)
                toast.error(error.response.data.detail?error.response.data.detail:error)
                console.log(error)
            })
        }
        
    }

  return (
    <React.Fragment>
        <Navbar/>
        <Toaster/>
        <div className=''>
            <form action="" className='md:px-2 md:py-2 flex flex-col' onSubmit={login}>
                     <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                            <div class="relative py-3 sm:max-w-xl sm:mx-auto">
                                <div
                                    class="absolute inset-0 bg-gradient-to-r from-primary3 to-primary2 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                                </div>
                                <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                                    <div class="max-w-md mx-auto">
                                        <div>
                                            <h1 className='text-center md:text-2xl text-lg md:font-bold font-raleway text-primary3 mt-6'> Welcome to Details education</h1>
                                            <h1 className='text-center md:text-md text-sm  font-raleway mt-2'> Registered students and staffs can log in to our website</h1>
                                        </div>
                                        <div className="divide-y divide-gray-200">
                                            <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                                <div class="relative">
                                                    <input autocomplete="off" id="username" name="username" type="text" class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Username" />
                                                    <label for="username" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm font-raleway">Username</label>
                                                </div>
                                                <div class="relative">
                                                    <input autocomplete="off" id="password" name="password" type="password" class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password" />
                                                    <label for="password" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm font-raleway">Password</label>
                                                </div>
                                                <div class="relative">
                                                    {isLoading?<Loader/>:<button class="bg-primary3 text-white rounded-md px-2 py-1 font-dosis hover:bg-primary2 hover:text-white">Login</button>}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </form>
        </div>
    </React.Fragment>
  )
}
