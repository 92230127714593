import React from 'react'
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
import Button from '../Button';
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { useState } from 'react';

import LoginButton from '../loginButton/loginButton';
import { AuthProvider } from '../../context/authContext';
import SubmitButton from '../docSubmit/submitButton';

export default function Navbar() {
  const [open, setOpen] = useState(false)
  return (
    <nav className='bg-white md:sticky md:top-0 md:z-50 px-2 py-4'>
      <div className='flex items-center font-medium justify-around '>
          <div className='z-50 p-2 md:w-auto w-full flex justify-between'>
            <a href='/'><img src={logo} alt="logo" className='md:cursor-pointer h-9'/></a>
            <div className='md:hidden'>  
            </div>
            
            {/* menu button for mobile view set to close on click*/}
            <div className=' text-3xl md:hidden ' onClick={()=>setOpen(!open)}>
              {open?<FaTimes className='text-burger'/>:<FaBars className='text-burger'/>}
            </div>
          </div>
        
          {/* navbar for large screns */}
          <ul className='md:flex hidden uppercase items-center gap-8 font-[Poppins]'>
     
            <li>
              <a href="/" className='py-7  px-3 inline-block text-lg font-raleway hover:text-primary2'>
                Home
              </a>
              
            </li>
          
            <NavLinks/>

          </ul>
          <div className='hidden md:flex md:gap-x-4 md:items-center '>
            <Button url="/contact" name={"Contact Us"} clickFn={()=>console.log("h")}/>
            <AuthProvider>
                <LoginButton/>
              </AuthProvider>
          </div>
          {/* navbar for mobiles on change in open state the animation plays*/}
          <ul className={
            ` bg-white absolute z-40 w-full h-full bottom-0 py-24 pl-4 duration-500 ${open? "left-0":"left-[-100%]"}`
          }>
            <li>
            
              <Link to="/" className='py-7 px-3 inline-block font-dosis text-lg'>
                Home
              </Link>
             
            </li>
            <NavLinks/>
            <div className='py-4 '>
            
              <Button url="/contact" name={"Contact Us"} clickFn={()=>console.log("h")}/>
              <AuthProvider>
                <LoginButton/>
              </AuthProvider>
            </div>
          </ul>
      </div>
      {/*<SubmitButton/>*/}
     
      
    </nav>
  )
}
