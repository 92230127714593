import sydney from '../assets/images/sydney1.jpg';
import usImage from '../assets/images/us.jpg';
import jpImage from '../assets/images/jp.jpg';
import nzImage from '../assets/images/nz.jpg';
import cndImage from '../assets/images/cnd.jpg';
import cypImage from '../assets/images/cyprus.jpg';
import ukImage from '../assets/images/uk.jpg';
import franceImage from '../assets/images/franceImage.jpg';

export const uiData={
    heroData:{
        heading:"Want to study abroad"
    },
    introData:{
        title:"WELCOME TO DETAIL EDUCATION",
        subtitle:"THE GUARDIAN ANGEL OF YOUR CAREER",
        description:"The Detail Education Consultancy aspires to provide you the best services and guidance to make your abroad study dream a reality. We have been providing over-the-top language classes, career counselling and visa procedures to guarantee success. Join us and sail smooth, we are happy to be a part of your bright future."
    },
    mapData:{
        countryMarkerData:[
            {
                title:"France",
                description:{
                    title:'France',
                    intro:'France, a captivating country in Western Europe, is renowned for its rich history, diverse culture, and exquisite cuisine. From the iconic Eiffel Tower in Paris to the sun-soaked beaches of the French Riviera, France offers a plethora of attractions and experiences for visitors. With its charming villages, world-class museums, and picturesque landscapes, this enchanting nation continues to captivate travelers with its timeless elegance and vibrant joie de vivre.',
                    whyText:`France is an ideal destination for students, offering renowned universities, quality education, and a vibrant student life. Students can benefit from affordable tuition fees, scholarships, and a diverse range of academic programs. Additionally, France's rich cultural heritage, stunning architecture, and cosmopolitan cities provide a stimulating and immersive environment for personal and intellectual growth.`,
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "Depending on the universities you might be asked for SATs and ACTs",
                        "GRE if you are applying for masters"
                    ],
                    documents:[
                        "visa application form",
                        "Passport",
                        "Passport size photos",
                        "Acceptance Letter issued by SDS Educational Institute in Canada",
                        "Result of the English Proficiency Test (IELTS, PTE)",
                        "Academic documents",
                        "Medical reports",
                        "Guaranteed Investment Certificate"
                    ],
                },
                image:franceImage
            },
            {
                title:"Canada",
                description:{
                    title:'Canada',
                    intro:'Low crime rates and a low cost of living are making Canada an international study hot spot for students. Canada is becoming a leading destination for international students as it provides student with affordable tuition fees, quality institutions, post-study work and immigration options. Students have been flocking to study in Canada since the 2016 announcement of a student visa that allows them to stay on and work in the country after graduation. In recent years Canada has experienced a huge increase in demand form the students who are willing study in abroad.',
                    whyText:'Students at present are flooded with options to choose between different courses as well as countries. Amongst many options if anyone is thinking about choosing Canada as a study destination, they should also understand, why study in Canada? What advantages can it provide against other countries? We all have our own priorities, be it related with affordability, safety, or something else. Before one decides to choose Canada for higher studies, he/she should also be familiar with the social benefits that the country can promise to the international students along with the guaranteed quality of education.',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "Depending on the universities you might be asked for SATs and ACTs",
                        "GRE if you are applying for masters"
                    ],
                    documents:[
                        "visa application form",
                        "Passport",
                        "Passport size photos",
                        "Acceptance Letter issued by SDS Educational Institute in Canada",
                        "Result of the English Proficiency Test (IELTS, PTE)",
                        "Academic documents",
                        "Medical reports",
                        "Guaranteed Investment Certificate"
                    ],
                },
                image:cndImage
            },
        
            {
                title:"Cyprus",
                description:{
                    title:'Cyprus',
                    intro:"Cyprus, officially the Republic of Cyprus,is an island country located south of the Anatolian Peninsula in the eastern Mediterranean Sea. It is geographically in Western Asia, but its cultural ties and geopolitics are overwhelmingly Southeastern European. Cyprus is the third-largest and third-most populous island in the Mediterranean.It is located north of Egypt, east of Greece, south of Turkey, and west of Lebanon and Syria. Its capital and largest city is Nicosia. The northeast portion of the island is de facto governed by the self-declared Turkish Republic of Northern Cyprus.",
                    whyText:'Students at present are flooded with options to choose between different courses as well as countries. Amongst many options if anyone is thinking about choosing Cyprus as a study destination, they should also understand, why study in Cyprus? What advantages can it provide against other countries? We all have our own priorities, be it related with affordability, safety, or something else. Before one decides to choose Cyprus for higher studies, he/she should also be familiar with the social benefits that the country can promise to the international students along with the guaranteed quality of education. One of the best reasons for nepalese to study in Cyprus might be the cost of living in Cyprus. It is fairly cheaper than other alternatives. It also has one of the best weathers if you are concrened about health of other lifestyle factors. It is a vacation destination so you might like staying there overall',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees",
                        "Good English",
                    ],
                    documents:[
                        "visa application form",
                        "Passport",
                        "Passport size photos",
                        "Admission Letter",
                        "Result of the English Proficiency Test (IELTS, PTE)*",
                        "Academic documents",
                        "Medical reports",
                        "Guaranteed Investment Certificate"
                    ],
                },
                image:cypImage
            },
            {
                title:"USA",
                description:{
                    title:'USA',
                    intro:"USA is one of the most popular countries when it comes to study destinations.The United States of America (U.S.A. or USA), commonly known as the United States (U.S. or US) or America, is a country primarily located in North America. It consists of 50 states, a federal district, five major unincorporated territories, nine Minor Outlying Islands, and 326 Indian reservations. The United States is also in free association with three Pacific Island sovereign states: the Federated States of Micronesia, the Marshall Islands, and the Republic of Palau. It is the world's third-largest country by both land and total area.",
                    whyText:'USA boasts one of the finest universities all around the world which makes it the most sought after destination for abroad studies. As a country with one of the largest economy, USA provides best career oppurtunities. The country also has one of the best companies that attract the job seekers or those who want to grow in the best environment. Being a country that welcomes student from all around the world, the country has an utopia of cultures.',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "IELTS, TOEFL or PTE language test",
                    
                    ],
                    documents:[
                        "visa application form",
                        "Passport",
                        "Passport size photos",
                        "recommendation Letter",
                        "Result of the English Proficiency Test (IELTS, PTE)",
                        "Academic documents",
                        "Medical reports",
                        "Guaranteed Investment Certificate"
                    ],
                },
                image:usImage
            },
            {
                title:"UK",
                description:{
                    title:'UK',
                    intro:"The United Kingdom of Great Britain and Northern Ireland, commonly known as the United Kingdom (UK) or Britain, is a sovereign state and country in Europe, off the north-western coast of the continental mainland. It comprises England, Scotland, Wales and Northern Ireland. The United Kingdom includes the island of Great Britain, the north-eastern part of the island of Ireland, and many smaller islands within the British Isles. Northern Ireland shares a land border with the Republic of Ireland; otherwise, the United Kingdom is surrounded by the Atlantic Ocean, the North Sea, the English Channel, the Celtic Sea and the Irish Sea. The total area of the United Kingdom is 242,495 square kilometres (93,628 sq mi), with an estimated 2020 population of more than 67 million people.",
                    whyText:'UK is known as the land of gentlemen boasting the finest quality of education all around the globe. UK has actually played a vital role in harnessing the education culture. As such UK houses universities with prestigious history and class whose students are bound to be seen in different light all around the world. UK also has a interesting culture and places ranging from beautiful countyrsides to cities full of life.',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "IELTS, TOEFL or PTE language test",
                    
                    ],
                    documents:[
                        "visa application form",
                        "Passport",
                        "Passport size photos",
                        "recommendation Letter",
                        "Result of the English Proficiency Test (IELTS, PTE)",
                        "Academic documents",
                        "Medical reports",
                        "CAS letter"
                    ],
                },
                image:ukImage
            },
            {
                title:"Japan",
                description:{
                    title:'Japan',
                    intro:"Japan ( Nippon or Nihon, and formally, Nihonkoku) is an island country in East Asia. It is situated in the northwest Pacific Ocean and is bordered on the west by the Sea of Japan, extending from the Sea of Okhotsk in the north toward the East China Sea, Philippine Sea, and Taiwan in the south. Japan is a part of the Ring of Fire, and spans an archipelago of 14,125 islands covering 377,975 square kilometers (145,937 sq mi); the five main islands are Hokkaido, Honshu (the 'mainland'), Shikoku, Kyushu, and Okinawa. Tokyo is the nation's capital and largest city, followed by Yokohama, Osaka, Nagoya, Sapporo, Fukuoka, Kobe, and Kyoto.",
                    whyText:'Japan is a country with great culture and hospitality. As a country which has left its mark in technology market Japan proves to be a great destination for the students specially in technical field.The ratio of students who go on to universities (undergraduate level) and junior colleges (regular courses) is also very high in Japan at 56.2%. This figure is indicative of the high standard of education in Japan. Many institutions of higher education, such as universities and junior colleges, are well equipped with fine research, computer, and library facilities and enable students to carry out their research in an excellent environment.',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "Japanese language test celarance as required by the university",
                    
                    ],
                    documents:[
                        "visa application form",
                        "Passport",
                        "Passport size photos",
                        "recommendation Letter",
                        "Result of the Japanese language Proficiency Test",
                        "Academic documents",
                        "Medical reports",
                    ],
                },
                image:jpImage
            },
            {
                title:"Australia",
                description:{
                    title:'Australia',
                    intro:"Australia, officially the Commonwealth of Australia, is a sovereign country comprising the mainland of the Australian continent, the island of Tasmania, and numerous smaller islands. With an area of 7,617,930 square kilometres (2,941,300 sq mi), Australia is the largest country by area in Oceania and the world's sixth-largest country. Australia is the oldest, flattest, and driest inhabited continent, with the least fertile soils. It is a megadiverse country, and its size gives it a wide variety of landscapes and climates, with deserts in the centre, tropical rainforests in the north-east, and mountain ranges in the south-east.",
                    whyText:'Australia is one of the most sought-after study destinations for Nepalese students. IT has always been a popular student destination among students for abroad studies because of the quality of education, lifestyle and the friendly nature of the Australian people who accept all other cultures all around. Every year, students from different parts of the world including Nepal visit this country in large numbers to avail themselves of the educational opportunities here. Having some of the world’s top Universities as its assets; the country offers the best educational environment for international students. There are over 22000 courses and 1100 institutions that span over more than 29 study areas like language, nursing, medicine, engineering, art, business, etc.',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "First division marks and good English skills",
                        "Bachelor degrees spanning 4 years"
                    
                    ],
                    documents:[
                        "visa application form",
                        "Identification document",
                        "Passport",
                        "Passport size photos",
                        "recommendation Letter",
                        "Result of the Japanese language Proficiency Test",
                        "Academic documents",
                        "Medical reports",
                    ],
                },
                image:sydney
            },
            {
                title:"New Zealand",
                description:{
                    title:'New Zealand',
                    intro:"New Zealand is an island country in the southwestern Pacific Ocean. It consists of two main landmasses—the North Island (Te Ika-a-Māui) and the South Island (Te Waipounamu)—and over 700 smaller islands. It is the sixth-largest island country by area, covering 268,021 square kilometres (103,500 sq mi). New Zealand is about 2,000 kilometres (1,200 mi) east of Australia across the Tasman Sea and 1,000 kilometres (600 mi) south of the islands of New Caledonia, Fiji, and Tonga. The country's varied topography and sharp mountain peaks, including the Southern Alps, owe much to tectonic uplift and volcanic eruptions. New Zealand's capital city is Wellington, and its most populous city is Auckland.",
                    whyText:'New Zealand has some of the most hospitable people in the world. It has great geography and climate to spend some time in.New Zealand is amazing for their education system. At first, tuition fee is some of the lowest in the world. The degrees are recognized around the world as being up-to-date and practical. You will get high quality hand on education that you deserve. The New Zealand even goes so far as to checking each and every course, program and certificate for quality so that they can be recognized around the world as high-quality education. The support services for international students are among the best in the world. They have a lot of expertise and experience in helping international students so that they can succeed in their programs.',
                    eligibility:[
                        "Students must have 12 years of secondary education for undergraduate degrees and 16 years of education for master's degree",
                        "Good English skills",
                        
                    
                    ],
                    documents:[
                        "visa application form",
                        "Identification document",
                        "Passport",
                        "Passport size photos",
                        "recommendation Letter",
                        "Result of the Japanese language Proficiency Test",
                        "Academic documents",
                        "Medical reports",
                        "Last three years income tax papers of all family members"
                    ],
                },
                image:nzImage
            },
          
        ],
         
        },
        
    
}