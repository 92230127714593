import React from 'react'
import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";


// import required modules
import { EffectCreative } from "swiper";
import Service from './service';
export default function ServiceSlide(props) {
  return (
    <React.Fragment>
         <Swiper   
        grabCursor={true}
        effect={"creative"}
        loop={true}
        creativeEffect={{
          limitProgress:2,
          prev: {
            shadow: true,
            translate: [0, 0,-400],
            opacity:0
            
          },
          next: {
            translate: ["100%", 0,0],
            
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper"
      >
        <SwiperSlide><Service data={props.data[0]}/></SwiperSlide>
        <SwiperSlide><Service data={props.data[1]}/></SwiperSlide>
        <SwiperSlide><Service data={props.data[2]}/></SwiperSlide>
        <SwiperSlide><Service data={props.data[3]}/></SwiperSlide>
      </Swiper>
    </React.Fragment>
  )
}
