export const links=[
    
    {name:'About us',submenu:true,hasState:false,sublinks:[
      {name:"Introduction",link:'/aboutUs'},
      {name:'Our team',link:'/team'},
      
      
    ]},
    {name:'Abroad study',submenu:true,hasState:true,sublinks:[
      {name:"Australia",link:'/country'},
      {name:'USA',link:'/country'},
      {name:'New Zealand',link:'/country'},
      {name:"Canada",link:'/country'},
      {name:'UK',link:'/country'},
      {name:'Japan',link:'/country'},
      {name:'Cyprus',link:'/country'},
      {name:'France',link:'/country'},
    ]},
    {name:'Training',submenu:true,hasState:false,sublinks:[
      {name:'IELTS',link:'/ielts'},
      {name:'PTE',link:'/pte'},
    ]},
   
  ]
  /* {name:'Resources',submenu:true,hasState:false,sublinks:[
    {name:'SOP Bank',link:'/'},
    {name:'PTE',link:'/'},
  ]},*/