import React from 'react'
export default function MyGoogleMap() {

  return (
    <React.Fragment>
        <div className='hidden md:block md:mx-5 md:my-6 border border-primary3 '>
            <iframe title="large" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14130.87903799014!2d85.3372971!3d27.6950551!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1966fcf11153%3A0x390cdb4e2d0b08f0!2sDetail%20Education%20Consultancy!5e0!3m2!1sen!2snp!4v1678206302870!5m2!1sen!2snp" width="800" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className='mx-5 my-6 border border-primary3 md:hidden mt-10'>
        <iframe title="mobile" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14130.87903799014!2d85.3372971!3d27.6950551!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1966fcf11153%3A0x390cdb4e2d0b08f0!2sDetail%20Education%20Consultancy!5e0!3m2!1sen!2snp!4v1678206466617!5m2!1sen!2snp" width="350" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
        </div>
    </React.Fragment>
  )
}
