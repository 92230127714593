import React from 'react'
import Navbar from '../components/navbar/navbar'
import { useState,useEffect } from 'react'
import RegisterButton from '../components/register/registerButton'
import StaffList from '../components/staffList/staffList'
import Footer from '../components/footer/footer'
import { Tooltip } from '@mantine/core'

export default function AdminPage() {
  return (
    <React.Fragment>
        <Navbar/>
        <div className='w-screen bg-primary2'>
          <h1 className='text-center text-white font-raleway font-bold py-2 md:text-xl md:py-2'>
            Admin Page
          </h1>
        </div>
       
        <div className=''>
        
          <StaffList/>
        </div>
        <div className='flex justify-center my-12 gap-x-6'>
        <RegisterButton/>
        <Tooltip label="Warning! This can cause problems if you modify files carelessly"><a target="_blank" href="https://kailash48.pythonanywhere.com/admin"><button  className='font-hkg bg-primary3 px-4 py-2 text-white rounded-lg' >Go to django Admin</button></a></Tooltip>
        </div>
        <Footer/>
    </React.Fragment>
  )
}
