import React, { useState,useContext } from "react";
import useAxios from "../../api/useAxios";
import AuthContext from "../../context/authContext";
export default function Settings() {
  const [oldPassword, setOldPassowrd] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatched] = useState(false);
  const [passwordStrong, setPasswordStrong] = useState(false);
  const [done,setDone]=useState(false)
  const [error,setError]=useState(null)
  let {user,logout}=useContext(AuthContext)
  const passwordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
let api=useAxios()
const changePassword=()=>{
    api.put('api/change-password/',{old_password:oldPassword,password:newPassword,password2:confirmPassword}).
    then(response=>
        setDone(true)
        ).catch(error=>
            {
                setError('error')
                console.log(error)
            }
            )
}

  return (
    <div className="flex justify-center items-center">
        {done?
        <div>
            <div className='flex flex-col items-center gap-y-4  px-4 py-2' >
                <h1 className='font-raleway'>Password changed</h1>
                <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
                    logout()
                }}>Ok</button>
                </div>
        </div>
        :error?
        <div>
            <div className='flex flex-col items-center gap-y-4 text-center'>
            <h1>Something went wrong!<br></br>{error}<br/>Please refresh and try again!</h1>
            <button className='w-16 rounded-md font-raleway bg-primary3 p-2' onClick={()=>{
                setNewPassword('')
                setOldPassowrd('')
                setConfirmPassword('')
                setPasswordMatched(false)
                setPasswordStrong(false)
                setError(false)
            }}>Ok</button>
            </div>
        </div>
        :<div className="font-raleway">
        <h1 className="mt-4 mb-6">Change Password</h1>
        <form className="flex flex-col w-full md:w-48" onSubmit={e=>{
            e.preventDefault()
            if(oldPassword.length>=8 && passwordMatch && passwordStrong){
                console.log('changing paswsw')
                changePassword()
            }
            else{
                setError('Invalid data')
            }

        }}>
          <label htmlFor="">Old Password</label>
          <input
            type="password"
            onChange={(e) => setOldPassowrd(e.target.value)}
            name="password1"
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
          />
          <label htmlFor="">New Password</label>
          <input
            type="password"
            onChange={(e) => {
              setNewPassword(e.target.value);
              if (passwordRegex.test(e.target.value)) {
                setPasswordStrong(true);
              }
              else{
                setPasswordStrong(false)
              }
            }}
            name="password1"
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
          />
          {passwordStrong ? (
            <div></div>
          ) : (
            <p className="font-raleway text-xs px-6 text-primary2">
              Password must contain, lowercase, Uppercase, number, special
              character and must be greater than 8 characters.
            </p>
          )}
          <input
            type="password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              console.log('passwors2 is',confirmPassword)
              if (newPassword === e.target.value) {
                setPasswordMatched(true);
              }
              else{
                setPasswordMatched(false)
              }
            }}
            name="password2"
            className="border-2 border-primary3 rounded-md w-64 my-2 px-2 py-1"
          />
          {passwordMatch ? (
            <div></div>
          ) : (
            <p className="font-raleway text-xs px-6 text-primary2">
              Password must match
            </p>
          )}
           <input  className={`w-24 bg-primary3 text-center text-white rounded-md p-1 my-2 opacity-100`} type="submit" value="Submit"/>
        </form>
      </div>
}
    </div>
  );
}
