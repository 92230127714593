import React from 'react'

export default function StudentDocumentView(props) {
    const link=props.file
    console.log('link is',link)
  return (
    <div className='w-full h-96 mt-4 font-raleway md:h-screen'>
        
    <iframe src={"https://docs.google.com/viewer?url="+{link}+"&embedded=true"+"#view=fitH"} frameborder="0" height='100%' width='100%'/>

</div>
  )
}
