import React from 'react'
import Footer from '../components/footer/footer';
import Navbar from '../components/navbar/navbar';
import { motion } from 'framer-motion';
import classImg from '../assets/images/class.jpg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import pteScore from '../assets/images/ptescore.png';
export default function TrainingToefl() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },);
  return (
    <React.Fragment>
        <Navbar/>
        <div className='overflow-x-hidden'>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary2 hidden md:block'
          initial={{x:'3000%',y:'700%'}}
          animate={{x:'300%',y:'2000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary3  hidden md:block'
          initial={{x:'3000%',y:'2700%'}}
          animate={{x:'900%',y:'1000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary2  hidden md:block'
          initial={{x:'3000%',y:'2700%'}}
          animate={{x:'300%',y:'2000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary3  hidden md:block'
          initial={{x:'3000%',y:'700%'}}
          animate={{x:'900%',y:'1000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='md:h-24 w-full bg-primary3 md:mb-10 h-12 mb-6'
           initial={{x:'100%'}}
           animate={{x:'0%'}}
           transition={{duration:1}}
          >
            <motion.h1 className='text-white font-tittilium md:text-5xl text-center md:py-6 text-xl py-3'
             initial={{opacity:0}}
             animate={{opacity:100}}
             transition={{duration:2,delay:1}}
            >
                TOEFL
            </motion.h1>
          </motion.div>
          <motion.div className='md:grid md:grid-cols-2 md:py-6 py-3'
          initial={{opacity:0}}
          animate={{opacity:100}}
          transition={{duration:2,delay:1}}
          >
            <div className=''>
                <img src={classImg} alt="" className='md:w-full rounded-full'/>
            </div>
            <div className='px-6 mt-6 md:mt-2'>
              <p className='font-dosis text-primary3 md:text-xl text-lg'>
              The TOEFL test has been taken by over 27 million people worldwide to ensure their English ability is adequate. The test is often taken by students who are planning to study at a university abroad and scholarship candidates, along with students and workers who are applying for visas and English-language learners tracking their English progress. Keep an eye out on the requirements for university courses, as it will be stated there whether you are required to take the TOEFL and the minimum grade required to apply for the course.

              </p>
              <Link to="/contact"><button className="font-dosis bg-primary2 border border-primary2 md:text-xl text-white rounded-full md:px-3 md:py-2 md:w-72 md:mt-6 w-72 h-12 text-lg hover:md:text-primary2 hover:md:bg-white mt-6">Contact Us for more details</button></Link>
            
            </div>
          </motion.div>
          <div className='md:my-12'>
            <h1 className='text-primary3 font-dosis md:text-3xl font-extrabold md:pl-16 pl-6 text-xl'>Toefl overview</h1>
            <p className='md:px-16 md:mt-4 text-primary3 md:text-xl font-dosis text-lg px-6'>
            If you are a international student looking to study in a university in an English speaking country, then you may need to take a TOEFL test. TOEFL is an acronym of the Test of English as a Foreign Language, which is the official name for the test. It assesses your ability to both speak and understand English by analysing your English ability in terms of reading, speaking, listening and writing. These are all skills which will be needed to carry out your academic studies and the test is used by institutions to ensure students are able to proceed and succeed on their chosen course in a country where the curriculum is taught in English.
            </p>
            <h1 className='text-primary3 font-dosis md:text-2xl font-extrabold md:pl-16 mt-4 text-xl pl-6'>Why Toefl?</h1>
            <p className='md:px-16 md:mt-4 text-primary3 md:text-xl font-dosis text-lg px-6'>
            Educational institutions and governmental agencies in over 130 countries accept TOEFL scores when considering applications for certain positions or courses. As an admission requirement, you may find that some courses require a minimum TOEFL score which will allow you to proceed onto the course alongside the regular course requirements. Minimum score requirements can range between 61 and 100 depending on the institution and course. The result you receive in your TOEFL test will only be valid for two years, after those two years they are no longer recorded, so if you’re looking to apply to study abroad after this time, you will have to sit the test again. It is important to ensure you take the test in time for your application if the result is part of the requirements; if you are only studying a course which lasts a year the same TOEFL results could be used to improve your opportunities for work in a foreign country also.
              </p>
          </div>
          <div className='md:mt-4'>
          <h1 className='text-primary3 font-dosis md:text-2xl font-extrabold md:pl-16 mt-4 pl-6 text-xl'>Score comparison</h1>
          <div className='flex justify-center md:px-24 px-6'>
          <img src={pteScore} alt="" className=''/>
          </div>
          
          </div>
          <div className='md:my-12'>
          <h1 className='text-primary3 font-dosis md:text-3xl font-extrabold md:pl-16 pl-6 text-xl'>What Toefl score do I need?</h1>
          <p className='md:px-16 md:mt-4 text-primary3 md:text-xl font-dosis px-6 text-lg'>
          Each educational establishment has their own requirements for TOEFL results, so knowing your personal goal is vital. To find this out, go to the admissions pages of your prospective schools. Language requirements for international applicants will be specified – with TOEFL scores (if required) listed. Generally speaking, TOEFL results of 90 or above are sufficient for most US universities. The more competitive a school is, the higher score it will typically ask for.
          </p>
          <a href="/contact"><button className="font-dosis bg-primary2 border border-primary2 md:text-xl text-white rounded-full md:px-3 md:py-2 md:w-72 md:mt-6 w-72 h-12 text-lg hover:md:text-primary2 hover:md:bg-white md:ml-16 mt-6 ml-6 mb-3">Contact Us to Enroll in a class</button></a>
          </div>
        </div>
        <Footer/>
    </React.Fragment>
  )
}
