import React from "react";
import { useState } from "react";
export default function ModalForm(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phNumber, setPHNumber] = useState('');
    const [country, setCountry] = useState('');
    const [mStatus, setMstatus] = useState('');
    const onNameChange = (event) => {
        setName(event.target.value);
        console.log('name',name);
       }
    const onEmailChange = (event) => {
        setEmail(event.target.value);
        console.log('email',email);
       }
    const onPhChange = (event) => {
        setPHNumber(event.target.value);
        console.log('ph',phNumber);
       }
    const onCountryChange = (event) => {
        setCountry(event.target.value);
        console.log('country',country);
       }
    const onmStatusChange = (event) => {
        setMstatus(event.target.value);
       }
    return (
        <React.Fragment>
        <form className="md:px-6" onSubmit={(e)=>{
            e.preventDefault();
            let data={
                name:name,
                email:email,
                phNumber:phNumber,
                country:country,
                mStatus:mStatus
            }
            props.submitHandle(data);
        }}>
            <div className="flex justify-between items-center">
                <label className=" font-raleway md:text-lg">Your Full name</label>
                <input onChange={onNameChange} type="text" name='fullname' className="rounded font-raleway  border border-solid border-primary3 py-1 px-3 md:text-lg"/>
            </div>
            <div className="flex justify-between mt-3 items-center">
                <label className=" font-raleway md:text-lg">Email</label>
                <input onChange={onEmailChange} type="text" name='email' className="rounded font-raleway border border-solid border-primary3 py-1 px-3 md:text-lg"/>
            </div>
            <div className="flex justify-between mt-3 items-center">
                <label className=" font-raleway md:text-lg">Phone Number</label>
                <input onChange={onPhChange} type="text" name="phone-number" pattern="[0-9]{10}" required className="rounded font-raleway border border-solid border-primary3 py-1 px-3 md:text-lg"></input>
            </div>
            <div className="flex justify-between mt-3 items-center">
                <label className=" font-raleway md:text-lg">Country of interest</label>
                <select onChange={onCountryChange} name='country' className="rounded font-raleway border border-solid border-primary3 py-1 px-3 md:text-lg">
                    <option value="">-Please choose an option-</option>
                    <option value="United States">US</option>
                    <option value="United kingdom">UK</option>
                    <option value="Canada">Canada</option>
                    <option value="Japan">Japan</option>
                    <option value="Australia">Australia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Cyprus">Cyprus</option>
                </select>
            </div>
            <div className="flex justify-between mt-3 items-center">
                <label className=" font-raleway md:text-lg">Marital Status</label>
                <select onChange={onmStatusChange} name='marital' className="rounded font-raleway border border-solid border-primary3 py-1 px-3 md:text-lg">
                    <option value="">-Please choose an option-</option>
                    <option value="Unmarried">Unmarried</option>
                    <option value="married">Married</option>           
                </select>
                
            </div>
            <button type="submit" className='font-raleway hover:text-primary2 hover:bg-white hover:border hover:border-primary2 bg-primary3 md:py-2 md:px-4 rounded-full text-white md:my-6 py-2 px-3 text-lg my-6'>Next</button>
        </form>
        </React.Fragment>
    );
};