import React from 'react'
import { useState,useEffect,useContext } from 'react';
import {FaUser}  from "react-icons/fa";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AuthContext from '../../context/authContext';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function LoginButton() {
    const location=useLocation();
    const navigate=useNavigate()
    let {user,logout}=useContext(AuthContext)
    const [showMenu, setShowMenu] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(user?true:false);
    const [isDashBoard,setIsDashBoard]=useState((location.pathname==="/admin" | location.pathname==="/staff" | location.pathname==="/student")?true:false)
    function goTo(){
        if(user.isStaff){
                if(user.isAdmin){
                    navigate("/admin")
                }
                else{
                    navigate("/staff")
                }
        }
        else{
            navigate("/student")
        }
    }
    function enableMenu(){
        setShowMenu(!showMenu)
        console.log(user)
    }
    function logoutUser(){
        setShowMenu(!showMenu)
        setIsLoggedIn(false)
        logout()
    }
  return (
    <React.Fragment>
    <div className='cursor-pointer rounded-full bg-primary3 md:bg-white px-2 py-1 w-28 h-10 flex justify-center items-center my-4 md:h-12 md:w-12' onClick={enableMenu}>
        <FaUser className='md:text-2xl text-white md:text-primary3'/>
        
    </div> 
    {
        isLoggedIn?(
            <React.Fragment>
                <motion.div onClick={logoutUser} className={` cursor-pointer fixed right-4 md:right-16 bottom-4 md:top-36 font-raleway text-white bg-primary2 md:h-8 h-24 flex items-center justify-center w-24  rounded-full text-center ${showMenu?'block':'hidden'}  hover:text-primary3`}
                initial={{y:'-50%'}}
                whileInView={{y:'0%'}}
                transition={{ease:'linear',duration:0.2}}
                >
                        Log out
                </motion.div>
            {
                !isDashBoard?
                (
                    
                <motion.div onClick={goTo} className={`cursor-pointer fixed right-4 md:right-16 bottom-32  md:top-24 font-raleway text-white bg-primary2 md:h-8 h-24 flex items-center justify-center w-24  rounded-full text-center ${showMenu?'block':'hidden'}  hover:text-primary3`}
                initial={{y:'-50%'}}
                whileInView={{y:'0%'}}
                transition={{ease:'linear',duration:0.2}}
                >
                        Dashboard
                </motion.div>
            
                ):null
            }
            
            </React.Fragment>
        ):(
        <Link to="/login"> 
            <motion.div className={`cursor-pointer fixed right-4 bottom-4 md:top-24 md:right-16 font-raleway text-white bg-primary2 md:h-8 h-24 flex items-center justify-center w-24  rounded-full text-center ${showMenu?'block':'hidden'}  hover:text-primary3`}
            initial={{y:'-50%'}}
            whileInView={{y:'0%'}}
            transition={{ease:'linear',duration:0.2}}>
                    Login
            </motion.div>
        </Link>
        )
    }
   
    </React.Fragment>
  )
}
