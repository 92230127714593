import React, { useState } from 'react'
import { Modal,LoadingOverlay} from '@mantine/core';
import {FaUserPlus}  from "react-icons/fa";
import { Tooltip } from '@mantine/core';
import RegisterModal from './registerModal';
export default function RegisterButton() {
    const [open,setOpen]=useState(false)
    const closeModal=()=>setOpen(false);
  return (
    <React.Fragment>
    <Tooltip label="Add new staff">
        <div className=' cursor-pointer' onClick={()=>{setOpen(true)}}>
            <FaUserPlus className='text-primary3 hover:text-primary2 text-6xl md:text-4xl'/>
        </div>
    </Tooltip>
    <RegisterModal opened={open} closeModal={closeModal} />
    </React.Fragment>
  )
}
