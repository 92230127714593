import React from 'react'
import Cta from '../cta'
import BookModal from './modal'
import { useState } from 'react'
export default function HeroHome() {
    const [openModal, setOpenModal] = useState(false);
    const closeModal=()=>setOpenModal(false);
  return (
    <div className='mt-4'>
    <p className='text-header mb-5 px-6 font-raleway md:text-center md:text-5xl md:mt-8 md:mb-10'> 
      <span className='text-tblack'>YOUR </span><span className='text-primary3'> SUCCESS</span><span className='text-tblack'> IS</span>
      <br/>
      <span className='text-tblack'>OUR </span><span className='text-tblack'> TOP</span><span className='text-primary3'> PRIORITY</span>
    </p>
    <p className='text-tblack px-6 font-hkg text-bodymd text-justify md:text-xl md:px-8'>
        Welcome to Detail Education Consultancy, where we help students achieve their academic goals and create a better future for themselves. Our team of experts is here to guide you through the entire process and make sure that you have access to the best educational opportunities available.
    </p>
    <div  onClick={()=>setOpenModal(true)} className='px-6 md:text-center'>
        <Cta buttonName="BOOK AN APPOINTMENT" />
    </div>
   
    <BookModal opened={openModal} closeModal={closeModal}/>
  </div>
  )
}
