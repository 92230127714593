import React from 'react'
import MyGoogleMap from './googleMap'
import { IoTime } from "react-icons/io5";
import {FaMapMarkedAlt} from "react-icons/fa";
export default function OfficeMap() {
  return (
    <div className='w-full'>
        <h1 className='font-raleway md:text-3xl font-bold text-center md:mt-4 md:mb-6 bg-primary3 text-white md:py-2 text-xl mt-6'>Visit Us</h1>
        <div className='md:flex md:mb-2 mb-10'>
            <MyGoogleMap/>
            <div className='w-full md:py-10'>
                <div className='flex items-center w-full justify-center md:gap-x-3 md:mt-6 gap-x-3'>
                    <FaMapMarkedAlt className='md:text-3xl text-xl text-primary3'/>
                    <h1 className='font-raleway font-bold md:text-3xl text-xl'>Office Location</h1>
                </div>
                 <h1 className='font-ralewy font-bold md:text-lg text-center md:mt-4'>Devkota Sadak, New Baneshwor</h1>
                 <div className='flex items-center w-full justify-center md:gap-x-3 md:mt-6 gap-x-3 mt-6'>
                    <IoTime className='md:text-3xl text-xl text-primary2'/>
                    <h1 className='font-raleway font-bold md:text-3xl text-xl'>Office hours</h1>
                </div>
                <h1 className='font-raleway font-bold md:text-lg text-center md:mt-4'>Sunday - Friday</h1>
                 <h1 className='font-ralewayfont-bold md:text-lg text-center md:mt-4'>9:00 AM - 6:00 PM</h1>
            </div>
        </div>
    </div>
  )
}
