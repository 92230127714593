import React from 'react'
import directorImg from '../../assets/images/dir.jpg';
export default function DirectorsWords() {
  return (
    <div className='md:w-full md:my-10'>
        
        <div className='md:grid md:grid-cols-2'>
            <div className='md:px-6 w-full'>
                <div className='w-half flex flex-col items-center md:py-6'>
                    <img src={directorImg} alt="" className='md:h-72 md:w-72 rounded-md h-72 md:pt-2'/>
                    <h1 className='text-center font-raleway  md:pt-3 text-xl font-bold mt-6'>Yogendra Chauhan</h1>
                    <h1 className='text-center font-raleway  font-bold text-lg '>Founder & CEO</h1>
                    <h1 className='text-center font-raleway font-bold text-lg mb-6'>Details education (P) ltd.</h1>
                </div>
               
            </div>
            <div className='md:py-6 md:px-6'>
                <p className='font-raleway text-xl mx-2 text-center md:text-justify  '>
                    <font size="+2">O</font>ur decision to study abroad is a good one, provided your application to your chosen destination country is backed by your profile, background, preference and experience. Studying abroad provides a fantastic opportunity to pursue your future career path. You may want to study abroad for a combination of reasons such as academic prowess, career and employment opportunities or something as simple as personal enrichment. For this, you may need detailed information on the required qualifications and what the educational framework of each destination country is like. When it comes to planning an international study, keep in mind your personal objectives while looking at key issues such as course availability, entry requirements, projected outcomes, costs, course duration, academic content, employment options during the study and so on!
                </p>
            </div>
        </div>
    </div>
  )
}
