import React from "react";
import { FaFileAlt } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import StudentRegisterForm from "../register/studentRegisterForm";
import StudentTable from "../staffList/studentTable";
export default function StudentList() {
  const data = [];
  return (
    <div className="px-4 py-12  font-raleway divide-y-2 md:py-1 md:w-screen md:flex md:divide-y-0 ">
      <div className="w-full h-72 overflow-y-scroll md:w-3/5 md:h-96">
        <StudentTable />
      </div>
      <div className=" mt-5 md:flex md:flex-col md:items-center  md:w-3/6 md:mt-1">
        <h1 className="text-center md:text-left font-bold mt-4 md:mt-0">
          Add new Student
        </h1>
        <StudentRegisterForm />
      </div>
    </div>
  );
}
