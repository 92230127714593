import React from 'react'
import { HoverCard } from '@mantine/core';
import { motion } from 'framer-motion';
import mark from '../../assets/images/loc.png';
import { Link } from 'react-router-dom';
export default function Marker(props) {
    const countries=props.countries;
    /*
    the data is pulled from uiData
    each marker is wrapped with HoverCard with custom details
    each marker has custom absolute positioning 
    */
  return (
    <React.Fragment>
        <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                    
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[310px] md:top-[90px] left-[40px] top-[10px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                    
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[0].description,image:countries[0].image}}>
                <HoverCard.Dropdown transition='skew-up'>
                    <img src={countries[0].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[0].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>

            <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                  
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[690px] md:top-[180px] left-[200px] top-[50px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                    
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[1].description,image:countries[1].image}}>
                <HoverCard.Dropdown>
                    <img src={countries[1].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[1].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>

            <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                  
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[340px] md:top-[140px] left-[50px] top-[45px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                    
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[2].description,image:countries[2].image}}>
                <HoverCard.Dropdown>
                    <img src={countries[2].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[2].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>

            <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                    
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[595px] md:top-[110px] left-[155px] top-[20px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                    
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[3].description,image:countries[3].image}}>
                <HoverCard.Dropdown>
                    <img src={countries[3].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[3].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>

            <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[950px] md:top-[170px] left-[320px] top-[40px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                   
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[4].description,image:countries[4].image}}>
                <HoverCard.Dropdown>
                    <img src={countries[4].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[4].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>

            <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                   
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[930px] md:top-[330px] left-[300px] top-[110px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                    
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[5].description,image:countries[5].image}}>
                <HoverCard.Dropdown>
                    <img src={countries[5].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[5].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>

            <HoverCard width={250} shadow="md" position='right-end' radius={'md'}>
                <HoverCard.Target>
                    
                        <motion.img src={mark} alt="" 
                        className={`absolute md:left-[1040px] md:top-[380px] left-[345px] top-[140px]`}
                        initial={{scale:1}}
                        animate={{scale:1.2}}
                        transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse"}}
                        />
                    
                </HoverCard.Target>
                <Link to='/country' state={{ data: countries[6].description,image:countries[6].image}}>
                <HoverCard.Dropdown>
                    <img src={countries[6].image} alt="" />
                    <div className='bg-primary3 md:mt-1 md:py-1'>
                        <h1 className='text-center text-white font-dosis md:py-2 font-bold md:text-xl'>{countries[6].title}</h1>
                    </div>
                </HoverCard.Dropdown>
                </Link>
            </HoverCard>
    </React.Fragment>
  )
}
