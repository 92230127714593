
import React from 'react'
import Footer from '../footer/footer'
import Navbar from '../navbar/navbar'
import Quote from './quote'
import { useState,useEffect } from 'react';
import AboutHero from './aboutHero';
import AboutBody from './aboutBody';
import DirectorsWords from './directorsWords';
import TeamSection from './team';
import Clock from '../clock/clock';

export default function AboutUs() {
  const [showIntro,setShowIntro] =useState(true);
  const transit=()=>setShowIntro(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  },);
  return (
    <React.Fragment>
        <Navbar/>
        {showIntro?<Quote endIntro={transit}/>
        :(
        <React.Fragment>
          
          <AboutHero/>
          <AboutBody/>
        </React.Fragment>
        )}
        <Footer/>
    </React.Fragment>
  )
}
