import React from 'react'
import { motion } from 'framer-motion'
export default function CountryHead(props) {
    console.log(props,'head');
  return (

    <div className='w-full md:h-half'>
        <motion.h1 className='bg-primary3 font-raleway md:text-4xl md:font-extra-bold text-white py-3 pl-3'
        initial={{x:'100%'}}
        animate={{x:'0%'}}
        transition={{duration:1}}
        >
            Study in {props.title}
        </motion.h1>
        <motion.img src={props.image} alt="" className=' md:h-96 md:w-full'
        initial={{opacity:0}}
        animate={{opacity:100}}
        transition={{duration:2}}
        />
    </div>

  )
}
