import React from 'react'
import { motion } from 'framer-motion';

export default function Quote(props) {
  return (
    <div className='h-screen flex w-full items-center justify-center'>
      <blockquote class="md:text-4xl italic text-primary3 text-center font-dosis md:mb-48">
         
          <motion.p
          animate={{opacity:[0,100,0]}}
          transition={{duration:2}}
          onAnimationComplete={props.endIntro}
          >"One’s destination is never a place, but a new way of seeing things." -Henry Miller</motion.p>
      </blockquote>
    </div>
  )
}
