import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/authContext'
function StudentRoute({children}) {
    let {user}=useContext(AuthContext);
   
    if(user && !user.isStaff && !user.isAdmin){
        return children
      }
      else{
        return <Navigate to="/login" replace={true} />
      }
}
export default StudentRoute