import React from "react";
import partyImage from "../../assets/images/party.png";
import visaFigure from "../../assets/images/visa.svg";
import q1 from "../../assets/images/quoteOpen.svg";
import q2 from "../../assets/images/quoteClose.svg";
import auFlag from "../../assets/images/auflag.png";
import ukFlag from "../../assets/images/ukFlag.png";
import usFlag from "../../assets/images/usFlag.png";
import jpFlag from "../../assets/images/jpFlag.png";
import cpFlag from "../../assets/images/cpFlag.png";
export default function SuccessCard(props) {
  const data = props.data;
  const flagImage = {
    australia: auFlag,
    uk: ukFlag,
    japan:jpFlag,
    us:usFlag,
    cyprus:cpFlag
  };
  return (
    <div className="my-4 py-6 md:py-2">
      <div className="flex mb-2 items-center justify-center gap-x-2">
        <h1 className="font-carr text-headxl md:text-6xl">Congratulations</h1>
        <img src={partyImage} alt="" className="w-10" />
      </div>
      <div className="flex justify-center">
        <div className="bg-primary2 h-72 w-72 bg-opacity-0 border-primary3 border-b-8 rounded-full absolute md:h-64 md:w-64"></div>
        <img
          src={data.image}
          alt=""
          className="rounded-full h-72 w-72 md:h-64 md:w-64"
        />
        <img
          src={visaFigure}
          alt=""
          className="absolute -translate-x-20 translate-y-40 md:-translate-x-20  md:translate-y-40"
        />
        <img
          src={flagImage[data.country.toLowerCase()]}
          alt=""
          className="absolute translate-x-20"
        />
      </div>
      <div className="mt-10 mb-8 font-raleway">
        <h1 className="text-headlg text-primary3 text-center tracking-wider md:text-xl">
          {data.name}
        </h1>
        <h1 className="text-center text-tblack text-cardhead md:text-xl">
          {data.country}
        </h1>
      </div>

      <blockquote class="flex flex-col md:px-48">
        <img src={q1} alt="" className="h-10 self-start ml-2 md:h-6" />
        <p className="font-hkg text-tblack text-bodymd text-center px-6 my-4 md:text-xl md:text-center">
          {data.text}
        </p>
        <img src={q2} alt="" className="h-10 self-end mr-2 md:h-6" />
      </blockquote>
    </div>
  );
}
