import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useContext } from "react";
import AuthContext from "../context/authContext";
const baseURL = "https://kailash48.pythonanywhere.com/";

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);
  const privateAxios = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authTokens === null ? "" : authTokens.access}`,
    },
  });
  privateAxios.interceptors.request.use(async (request) => {
    if (authTokens !== null) {
      const user = jwtDecode(authTokens.access);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (!isExpired) return request;
      else {
        console.log("expired")
        const response = await axios.post(`${baseURL}api/token/refresh/`, {
          refresh: authTokens.refresh,
        });
        localStorage.setItem("authToken", JSON.stringify(response.data));
        setAuthTokens(response.data);
        setUser(jwtDecode(response.data.access));
        request.headers.Authorization = `Bearer ${response.data.access}`;
        return request;
      }
    } else {
      alert("not logged in");
    }
  });
  return privateAxios;
};
export default useAxios;
