import React from 'react'
import { Modal} from '@mantine/core';
import useAxios from '../../api/useAxios';
import { useState } from 'react'

export default function StaffDeleteModal(props) {
    const [done,setDone]=useState(false)
    let api=useAxios()
    const deleteStaff=()=>{
       api.delete('api/staff',{data:{
        id: props.deleteData.id
      }}).
       then(
        (response)=>{
        console.log(response)
        props.deleteLocally()
        window.location.reload(true);
        props.closeModal()
    }
        )
      .catch((error)=>{
          console.log(error)
         
      })
    }
  return (
    <React.Fragment>
        <Modal
        size='sm'
        opened={props.opened}
        onClose={() => {
          props.closeModal();
        }}
        overlayBlur={4}
        title="Delete Staff"
        transition='skew-up'
        exitTransition='skew-up'
        transitionDuration={500}
        exitTransitionDuration={500}
        transitionTimingFunction='ease'
        closeOnClickOutside={false}
        >
            {done?(
                <div>
                    <h1 className='font-raleway'>Successfully Deleted</h1>
                </div>
            )
            :<div className='flex flex-col justify-center items-center text-center'>
                <h1 className='font-hkg text-center font-bold text-primary2 '>Do you want to delete {props.deleteData.staff}?</h1>
                <div className='flex gap-x-10 mt-4'>
                    <div onClick={()=>deleteStaff()}>
                    <button  className='font-hkg bg-primary2 px-4 py-2 text-white rounded-lg'>Yes</button>
                    </div>
                    <div onClick={()=>props.closeModal()}>
                    <button  className='font-hkg bg-primary3 px-4 py-2 text-white rounded-lg' >No</button>
                    </div>
                   
                </div>
            </div>}
        </Modal>
    </React.Fragment>
  )
}
