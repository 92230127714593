import React from 'react'
import { motion } from 'framer-motion'
import pic from '../../assets/images/pic.png';
import PhoneCard from './phoneCard';
import EmailCard from './emailCard';
import { useState } from 'react';
import Button from '../Button';
import BookModal from '../hero/modal';
import Cta from '../cta';
export default function Intro() {
    const [openModal, setOpenModal] = useState(false);
    const closeModal=()=>setOpenModal(false);
  return (
    <div className='md:flex'>
        <motion.img src={pic} alt="" className='md:h-full md:w-full md:hidden'
        initial={{opacity:0}}
        animate={{opacity:100}}
        transition={{duration:1}}
        />
        <div className='md:w-full flex flex-col font-raleway items-center justify-items-center overflow-x-hidden'>
       
            <PhoneCard/>
            <EmailCard/>
            <div  onClick={()=>setOpenModal(true)} className='px-6 md:text-center'>
              <Cta buttonName="BOOK AN APPOINTMENT" />
            </div>
          
            <BookModal opened={openModal} closeModal={closeModal}/>
            <div className='my-3'></div>
        </div>
    </div>
  )
}