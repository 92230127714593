import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/authContext'

function AdminRoute({children}) {
    let {user}=useContext(AuthContext);
  if(user && user.isAdmin){
    return children
  }
  else{
    return <Navigate to="/login" replace={true} />
  }
 
}
export default AdminRoute