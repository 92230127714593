import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/authContext'
function StaffRoute({children}) {
    let {user}=useContext(AuthContext);
    console.log(user)
    if(user && user.isStaff && !user.isAdmin){
        return children
      }
      else{
        return <Navigate to="/login" replace={true} />
      }
}
export default StaffRoute