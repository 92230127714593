import React from 'react';
import Navbar from '../components/navbar/navbar';
import Hero1 from '../components/hero/hero1'
import Intro from '../components/intro/intro';
import Map from '../components/map/map';
import Footer from '../components/footer/footer';
import UnishowShowCase from '../components/unishowcase/uniShowCase';
import WhyUs from '../components/whyUs/whyUs';
import Affiliations from '../components/affiliations/affiliations';
import Features from '../components/aboutUs/features';
import HeroHome from '../components/hero/heroHome';
import Services from '../components/services/services';
import Steps from '../components/steps/steps';
import SuccessStories from '../components/successStories/successStories';


export default function Homepage() {
  return (
    <div className='overflow-x-hidden'>
      <Navbar/>
      <HeroHome/>
      <Affiliations/>
      <Services/>
      <Map/>
      <Steps/>
      <SuccessStories/>
      <UnishowShowCase/>
      {/*<Intro/>
      <Features/>
      
      
  */}
      <Footer/>
     </div>
  )
}
