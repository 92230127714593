import React from 'react'
import bmap20 from '../../assets/images/bmap20.png';
import planeCursor from '../../assets/images/plane.png';
import { uiData } from '../../data/uidata';
import CountryCard from './countryCard';
import Marker from './marker';
export default function Map() {
    const countries=uiData.mapData.countryMarkerData;
    /*
    the div contains an image of world map
    Markers are placed on 7 countries
    */
  return (
    <React.Fragment>
      <div className='md:hidden'>
      <p className='px-6 text-center tracking-wide text-tblack relative z-90'>
            <span className='font-raleway text-headlg'>Explore Top</span> <span className='font-raleway text-headlg text-primary3'>Study Destinations </span>
            <span className='font-raleway text-headlg'>Around the </span><span className='font-raleway text-headlg text-primary3'>world</span>
        </p>
          {countries.map((country)=><CountryCard country={country}/>)}
      </div>
      <div className='h-screen md:py-3 md:px-10 w-full hidden md:block'>
          <h1 className='text-center text-primary3 md:text-4xl md:font-bold'>Top Study Destinations Around the World</h1>
          <div className='relative md:pl-48'>
              <img src={bmap20} alt="" className='md:left-48 md:mt-6'/>
              <Marker countries={countries}/>
          </div>
      </div>
      </React.Fragment>
  )
}
