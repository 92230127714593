import React, { useEffect } from "react";
import useAxios from "../api/useAxios";
import Navbar from "../components/navbar/navbar";
import { useContext, useState } from "react";
import AuthContext from "../context/authContext";
import { useLocation } from "react-router-dom";
import StudentProfilePreview from "../components/profile/studentProfilePreview";
import DocViewerComponent from "../components/profile/docViewer";
import { Loader } from "@mantine/core";

export default function StudentPreview() {
  const { state } = useLocation();
  const profile = state.profile;
  const [documents,setDocuments]=useState(null)
  const api=useAxios()
console.log('profile is',profile)
  const getDocuments=()=>{
    console.log('id in get is ',profile.id)
    const data={
        id:profile.id
    }
    const config = {
      
        data: {id:profile.id},
        params: {
            "id": profile.id
        }
    }
    api.get('/api/student/admin-documents/',config).
    then(response=>
        {
        setDocuments(response.data)
        console.log('new docs',documents)
        }
        ).catch
        (
            error=>
            console.log(error)
        )
  }


  console.log("id-", state.id);
  useEffect(() => {
   getDocuments()
  },[]);
  return (
    <React.Fragment>
      <Navbar />
      <StudentProfilePreview data={profile} />
      {documents?<DocViewerComponent data={documents} />:
      <div className="flex justify-center items-center">
        <Loader/>
      </div>
      }
    </React.Fragment>
  );
}
