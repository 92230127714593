import React from 'react';
import { motion } from 'framer-motion';
export default function Score() {
const data=[
    {
        title:'Did not attempt the test',
        desc:'The test taker did not answer the questions.'
    },
    {
        title:'Non-user',
        desc:'The test taker has no ability to use the language except a few isolated words.'
    },
    {
        title:'Intermittent user',
        desc:"The test taker has great difficulty understanding spoken and written English."
    },
    {
        title:'Extremely limited user',
        desc:"The test taker conveys and understands only general meaning in very familiar situations. There are frequent breakdowns in communication."
    },
    {
        title:'Limited user',
        desc:"The test taker's basic competence is limited to familiar situations. They frequently show problems in understanding and expression. They are not able to use complex language."
    },
    {
        title:'Modest user',
        desc:"The test taker has a partial command of the language and copes with overall meaning in most situations, although they are likely to make many mistakes. They should be able to handle basic communication in their own field."
    },
    {
        title:'Competent user',
        desc:"The test taker has an effective command of the language despite some inaccuracies, inappropriate usage and misunderstandings. They can use and understand fairly complex language, particularly in familiar situations."
    },
    {
        title:'Good user',
        desc:"The test taker has operational command of the language, though with occasional inaccuracies, inappropriate usage and misunderstandings in some situations. They generally handle complex language well and understand detailed reasoning."
    },
    {
        title:'Very good user',
        desc:"The test taker has fully operational command of the language with only occasional unsystematic inaccuracies and inappropriate usage. They may misunderstand some things in unfamiliar situations. They handle complex and detailed argumentation well."
    },
    {
        title:'Expert user',
        desc:"The test taker has fully operational command of the language. Their use of English is appropriate, accurate and fluent, and shows complete understanding."
    }
];
const scoreIllustration=data.map((score,index)=>(
    <div className='flex flex-col md:flex-row justify-between md:px-32 items-center md:py-6 px-6'>
       <motion.div className='bg-primary3 h-24 md:h-32 w-24 md:w-32 rounded-full flex justify-items-center items-center justify-center'
       initial={{opacity:0}}
       whileInView={{opacity:100}}
       transition={{duration:2}}
       viewport={{once:true}}
       >
           <h1 className='text-white font-dosis md:text-5xl font-extrabold text-3xl'>{index}</h1>
       </motion.div>
       
       <motion.div className='md:px-6 py-4'
       initial={{x:"-100%"}}
       whileInView={{x:'0%'}}
       transition={{duration:1}}
       viewport={{once:true}}
       >
           <h1 className='md:text-2xl font-bold font-raleway  text-xl'>{score.title}</h1>
           
       </motion.div>
       <motion.div className='md:w-72'
       initial={{x:"-200%"}}
       whileInView={{x:'0%'}}
       transition={{duration:1}}
       viewport={{once:true}}
       >
           <p className='md:text-lg font-raleway text-justify text-sm'>{score.desc}</p>
       </motion.div>

   </div>
));
  return (
    <div className='md:mt-6 md:mb-6 overflow-x-hidden mt-3'>
           {scoreIllustration}
    </div>
  )
}
