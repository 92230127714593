import React from "react";

import { useState } from "react";

import DocumentCard from "../docs/documentCard";
import { FaCheckCircle, FaCheckSquare } from "react-icons/fa";
import useAxios from "../../api/useAxios";

export default function DocViewerComponent(props) {
  const docs = props.data;
  let api=useAxios()
  const categories = [];
  const [approveId, setApproveId] = useState(null);
  const [feedbackId, setFeedbackId] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const approveDocument = (index) => {
    api.post('api/student/admin-verification/',{id:approveId}).
    then(response=>{
      window.location.reload(true)
    }).catch(error=>
        console.log(error))
  };
  const commentDocument = (index) => {
    api.post('api/student/admin-feedback/',{id:feedbackId,feedback:feedback}).
    then(response=>{
      window.location.reload(true)
    }).catch(error=>
        console.log(error))
  };
  docs.forEach((doc) => {
    categories.push(doc.category);
  });
  const [docList, setDocList] = useState(docs);
  const [filterString, setFilteredString] = useState("All Documents");
  const filterCategory = (category) => {
    setFilteredString(category);
    setDocList(docs.filter((doc) => doc.category === category));
  };
  return (
    <div className="px-4 py-4 md:px-12">
      <div className="flex justify-between">
        <button
          onClick={() => {
            setFilteredString("All Documents");
            setDocList(docs);
          }}
          className="bg-primary3 text-white font-raleway text-md rounded-md px-2 py-1"
        >
          View all
        </button>

        <form>
          <select
            onChange={(e) => filterCategory(e.target.value)}
            name="categories"
            id=""
            className="font-ratelway border rounded-md border-primary3 px-2 py-1"
          >
            {categories.map((category) => (
              <option value={category} className="py-1">
                {category}
              </option>
            ))}
          </select>
        </form>
      </div>
      <h1 className="font-raleway text-center my-4">Showing {filterString}</h1>
      {docList.map((doc) => (
        <React.Fragment>
          <h1 className="text-xl font-bold text-center mt-6">
            {doc.category.toUpperCase()}
          </h1>
          <DocumentCard doc={doc} />
          <div className="w-full bg-tblack text-white flex flex-col md:flex-row gap-y-2 px-4 md:justify-between py-2 items-center">
            {doc.verified ? (
              <div class="text-xs flex justify-between items-center gap-x-1">
                <FaCheckCircle />
                <h1>Approved by {doc.verified_by}</h1>
              </div>
            ) : (
              <button
                onClick={() => {
                    setApproveId(doc.id)
                    console.log("approve id is",approveId)
                    approveDocument(docList.indexOf(doc))
                }}
                className="bg-white text-primary3 text-sm font-raleway text-md rounded-md px-2 py-1"
              >
                Approve
              </button>
            )}
            <form className="font-raleway flex gap-x-2"> 
              <label htmlFor="">Feedback</label>
              <input type="text" onChange={e=>setFeedback(e.target.value)} value={feedback} className="text-tblack"/>
                <button className="rounded-md p-1 bg-primary2" onClick={(e)=>{
                    e.preventDefault()
                    console.log("the id for feedback",doc.id)
                    setFeedbackId(doc.id)
                    console.log("feedback id is",feedbackId)
                    console.log("feedback is",feedback)
                    commentDocument()
                }}>Submit</button>
            </form>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
