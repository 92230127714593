import React from 'react'
import Footer from '../components/footer/footer';
import Navbar from '../components/navbar/navbar';
import { motion } from 'framer-motion';
import classImg from '../assets/images/class.jpg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Score from '../components/score/score';
export default function TrainingIelts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },);
  return (
    <React.Fragment>
        <Navbar/>
        <div className='overflow-x-hidden'>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary2 hidden md:block'
          initial={{x:'3000%',y:'700%'}}
          animate={{x:'300%',y:'2000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary3  hidden md:block'
          initial={{x:'3000%',y:'2700%'}}
          animate={{x:'900%',y:'1000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary2  hidden md:block'
          initial={{x:'3000%',y:'2700%'}}
          animate={{x:'300%',y:'2000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary3  hidden md:block'
          initial={{x:'3000%',y:'700%'}}
          animate={{x:'900%',y:'1000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='md:h-24 w-full bg-primary3 md:mb-10 h-12 mb-6'
           initial={{x:'100%'}}
           animate={{x:'0%'}}
           transition={{duration:1}}
          >
            <motion.h1 className='text-white font-hkg md:text-5xl md:py-6 text-xl py-3 px-3'
             initial={{opacity:0}}
             animate={{opacity:100}}
             transition={{duration:2,delay:1}}
            >
                IELTS
            </motion.h1>
          </motion.div>
          <motion.div className='md:grid md:grid-cols-2 md:py-6 py-3'
          initial={{opacity:0}}
          animate={{opacity:100}}
          transition={{duration:2,delay:1}}
          >
            <div className=''>
                <img src={classImg} alt="" className='md:w-full rounded-lg'/>
            </div>
            <div className='px-6 mt-6 md:mt-2'>
              <p className='font-raleway text-justify text-lg'>
              The IELTS course aims to prepare students with the skills and strategies needed to undertake the IELTS test with confidence and to succeed in the IELTS examination. The course will give students intensive practice in each module, making sure the students know exactly how to make best use of their time. Students will also do practice tests in each skill under exam conditions and instructors will give them feedback on their performance to help them improve.
              </p>
              <Link to="/contact"><button className="font-raleway bg-primary3 border border-primary2 md:text-lg text-white rounded-full md:px-3 md:py-2 md:w-64 md:mt-6 w-72 h-12 text-lg hover:md:text-primary2 hover:md:bg-white mt-6">Contact Us</button></Link>
            
            </div>
          </motion.div>
          <div className='md:my-12'>
            <h1 className='font-raleway md:text-3xl font-extrabold md:pl-16 pl-6 text-xl'>IELTS overview</h1>
            <p className='md:px-16 md:mt-4 text-justify md:text-lg font-raleway text-lg px-6'>
              The International English Language Testing System (IELTS) is designed to help you work, study or migrate to a country where English is the native language. This includes countries such as Australia, Canada, New Zealand, the UK and USA.
              Your ability to listen, read, write and speak in English will be assessed during the test. IELTS is graded on a scale of 1-9. 
              IELTS is jointly owned by the British Council; IDP IELTS; and Cambridge University Press & Assessment. 
            </p>
            <h1 className=' font-raleway md:text-2xl font-extrabold md:pl-16 mt-4 text-xl pl-6'>Why IELTS?</h1>
            <p className='md:px-16 md:mt-4 text-justify  md:text-lg font-raleway text-lg px-6'>
              If you are looking to work, live or study in an English-speaking country, then you must be able to demonstrate a high level of English language ability.
              English is the third most spoken language in the world, with 379 million speakers worldwide.
              Being able to communicate in the native language of the country you wish to work or study in, has a wide range of benefits. It is also essential for job opportunities as well as integration into the community.
              IELTS is the most popular test for those looking to migrate to Australia, Canada, New Zealand and the UK. It is globally recognised by more than 11,000 employers, universities, schools and immigration bodies including 3,400 institutions in the USA.
              </p>
          </div>
          <div className='md:mt-4'>
          <h1 className='text-primary3 font-dosis md:text-2xl font-extrabold md:pl-16 mt-4 pl-6 text-xl'>IELTS score system</h1>
          <Score/>
          </div>
          <div className='md:my-12'>
          <h1 className='font-raleway md:text-3xl font-extrabold md:pl-16 pl-6 text-xl'>What IELTS score do I need?</h1>
          <p className='md:px-16 md:mt-4 text-justify  md:text-lg font-raleway px-6 text-lg'>
            The higher you can score in your IELTS, reflects a better understanding and ability to communicate in English. Each immigration body, university, workplace or institution will have specific IELTS score requirements. The score you need will depend on what you are looking to do in the country, i.e work or study. 
          </p>
          
          </div>
        </div>
        <Footer/>
    </React.Fragment>
  )
}
