import React from 'react'
import { Modal} from '@mantine/core';
import StudentDocumentView from './studentDocumentView';


export default function DocViewModal(props) {
    const link=props.link
  return (
    <React.Fragment>
        <Modal
        size='xl'
        opened={props.opened}
        onClose={() => {
          props.closeModal();
        }}
        overlayBlur={2}
        title={"Document preview"}
        transition='skew-up'
        exitTransition='skew-up'
        transitionDuration={500}
        exitTransitionDuration={500}
        transitionTimingFunction='ease'
        closeOnClickOutside={false}
        >
            <StudentDocumentView link={link}/>
          
        </Modal>
    </React.Fragment>
  )
}
