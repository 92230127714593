import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/navbar";
import { useContext } from "react";
import { Toaster,toast } from "react-hot-toast";
import useAxios from "../api/useAxios";
import {
  FaUserCircle,
  FaFile,
  FaUniversity,
  FaCalendarCheck,
  FaUserGraduate,
  FaTrophy,
} from "react-icons/fa";
import { Tabs } from "@mantine/core";
import AuthContext from "../context/authContext";
import StudentList from "../components/tab/studentList";
import BookingPage from "../components/bookingPage";
import UniverityPage from "../components/univerityPage";
import StoryPanel from "../components/storyPanel";
import DocumentsPanel from "../components/docs/documentsPanel";
import Footer from "../components/footer/footer";
export default function StaffPage() {

  let api = useAxios();
  const [data, setData] = useState(null);
  useEffect(() => {
    api
      .get("api/isloggedin/")
      .then((response) => {
        setData(response.data);
        console.log("datastaff", response.data);
      })
      .catch((error) => {
        console.log(error);
        console.log("error");
      });
  },[]);
  return (
    <React.Fragment>
      <Navbar />
      <Toaster/>
      <div>
        <div className="flex justify-center items-center gap-x-2 my-4 overflow-x-hidden">
          <FaUserCircle className="text-2xl" />
          <h1 className="font-hkg text-2xl ">{data ? data.name : ""}</h1>
        </div>
        <Tabs color="teal" defaultValue="students" orientation="vertical">
          <Tabs.List>
            <Tabs.Tab value="students">
              <div className="flex justify-between items-center gap-x-2">
                <FaUserGraduate className="text-2xl" />
                <h1 className="hidden md:block">Students</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="bookings" color="blue">
              <div className="flex justify-between items-center gap-x-2">
                <FaCalendarCheck className="text-2xl" />
                <h1 className="hidden md:block">Bookings</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="university" color="blue">
              <div className="flex justify-between items-center gap-x-2">
                <FaUniversity className="text-2xl" />
                <h1 className="hidden md:block">University</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="story" color="blue">
              <div className="flex justify-between items-center gap-x-2">
                <FaTrophy className="text-2xl" />
                <h1 className="hidden md:block">story</h1>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value="documents" color="blue">
              <div className="flex justify-between items-center gap-x-2">
                <FaFile className="text-2xl" />
                <h1 className="hidden md:block">Documents</h1>
              </div>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="students" pt="xs">
            <StudentList />
          </Tabs.Panel>

          <Tabs.Panel value="bookings" pt="xs">
            <BookingPage />
          </Tabs.Panel>
          <Tabs.Panel value="university" pt="xs">
            <UniverityPage />
          </Tabs.Panel>
          <Tabs.Panel value="story" pt="xs">
            <StoryPanel />
          </Tabs.Panel>
          <Tabs.Panel value="documents" pt="xs">
            <DocumentsPanel />
          </Tabs.Panel>
        </Tabs>
      </div>
      <Footer />
    </React.Fragment>
  );
}
