import React from 'react'
import Footer from '../components/footer/footer';
import Navbar from '../components/navbar/navbar';
import { motion } from 'framer-motion';
import classImg from '../assets/images/class.jpg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import pteScore from '../assets/images/ptescore.png';
export default function TrainingPte() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },);
  return (
    <React.Fragment>
        <Navbar/>
        <div className='overflow-x-hidden'>
          <motion.div className='absolute z-20 h-4 w-4 rounded-full bg-primary2 hidden md:block'
          initial={{x:'3000%',y:'700%'}}
          animate={{x:'300%',y:'2000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary3  hidden md:block'
          initial={{x:'3000%',y:'2700%'}}
          animate={{x:'900%',y:'1000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary2  hidden md:block'
          initial={{x:'3000%',y:'2700%'}}
          animate={{x:'300%',y:'2000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='absolute z-50 h-4 w-4 rounded-full bg-primary3  hidden md:block'
          initial={{x:'3000%',y:'700%'}}
          animate={{x:'900%',y:'1000%'}}
          transition={{ease:'linear',repeat:'Infinity',repeatType: "reverse",duration:6,delay:2}}
          ></motion.div>
          <motion.div className='md:h-24 w-full bg-primary3 md:mb-10 h-12 mb-6'
           initial={{x:'100%'}}
           animate={{x:'0%'}}
           transition={{duration:1}}
          >
            <motion.h1 className='text-white font-raleway md:text-5xl md:px-3 md:py-6 text-lg py-3'
             initial={{opacity:0}}
             animate={{opacity:100}}
             transition={{duration:2,delay:1}}
            >
                PTE
            </motion.h1>
          </motion.div>
          <motion.div className='md:grid md:grid-cols-2 md:py-6 py-3'
          initial={{opacity:0}}
          animate={{opacity:100}}
          transition={{duration:2,delay:1}}
          >
            <div className=''>
                <img src={classImg} alt="" className='md:w-full rounded-lg'/>
            </div>
            <div className='px-6 mt-6 md:mt-2'>
              <p className='font-raleway  md:text-lg text-lg text-justify'>
              Pearson Test of English (Academic) is an English language proficiency test that is designed to assess the competence or the language ability level of non-native English speakers. It assesses all skills of the English language: Speaking, Writing, Reading, and Listening. PTE is one of the internationally recognized tests which is accepted by several universities, colleges, and academic institutions including government agencies. It is a computer-based test that is valid for 2 years.
              </p>
              <Link to="/contact"><button className="font-raleway bg-primary3 border border-primary2 md:text-lg text-white rounded-full md:px-3 md:py-2 md:w-64 md:mt-6 w-72 h-12 text-lg hover:md:text-primary2 hover:md:bg-white mt-6">Contact Us</button></Link>
            
            </div>
          </motion.div>
          <div className='md:my-12'>
            <h1 className=' font-raleway md:text-3xl font-extrabold md:pl-16 pl-6 text-lg'>PTE overview</h1>
            <p className='md:px-16 md:mt-4  md:text-lg font-raleway text-lg px-6 text-justify'>
            The Pearson Test of English (PTE Academic) is an English proficiency test that assesses your speaking, writing, listening and reading abilities. Many individuals must take it in order to to study abroad in an English speaking country like Australia, or when they wish to become a permanent resident of such a country. We hope to give you detailed information about how you can prepare for the PTE and feel comfortable that you know what you’re getting into!
            </p>
            <h1 className=' font-raleway md:text-2xl font-extrabold md:pl-16 mt-4 text-lg pl-6'>Why PTE?</h1>
            <p className='md:px-16 md:mt-4  md:text-lg font-raleway text-justify text-lg px-6'>
              One thing that many people appreciate about the PTE academic is that it is completely computer based, and therefore you can complete the entire thing in one sitting over the course of about 3 hours. All responses (including speaking) are assessed by computer, and for a lot of people this takes away the stress of having to speak with a human examiner.
              The PTE Academic can be scheduled with only 24 hours notice and there are constantly tests taking place, making it easy to suit various busy lifestyles. It also provides results quickly – usually in under 5 days – which is ideal for students who need their results in a hurry.
              </p>
          </div>
          <div className='md:mt-4'>
          <h1 className=' font-raleway md:text-2xl font-extrabold md:pl-16 mt-4 pl-6 text-lg'>PTE score comparison</h1>
          <div className='flex justify-center md:px-24 px-6'>
          <img src={pteScore} alt="" className=''/>
          </div>
          
          </div>
          <div className='md:my-12'>
          <h1 className=' font-raleway md:text-3xl font-extrabold md:pl-16 pl-6 text-lg'>What PTE score do I need?</h1>
          <p className='md:px-16 md:mt-4  md:text-lg font-raleway px-6 text-lg text-justify'>
            The higher you can score in your PTE, reflects a better understanding and ability to communicate in English. Each immigration body, university, workplace or institution will have specific PTE score requirements. The score you need will depend on what you are looking to do in the country, i.e work or study. 
          </p>
          
          </div>
        </div>
        <Footer/>
    </React.Fragment>
  )
}
